import { Link } from "react-router-dom"

function Logo(props: any) {
    return (
        <Link to="/">
            <img src="/images/Logo Maxi_Logotypemaxi.png" className={`w-36 px-4 py-4 ${props.className ?? ""}`} alt="" />
        </Link>
    )
}

export default Logo