import Appbar from "Component/Appbar";
import { SectionDemo } from "Component/Footer";
import config from "Config";
import utils from "Utils";
import { ArrowDown2 } from "iconsax-react";
import { useState } from "react";

export default function Faq() {
  return (
    <div className="text-center">
      <div
        style={{
          backgroundImage: `url('${config.AWS_URL_IMAGES_LANDING}/images/employer/bg_home.png')`,
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        <Appbar isReverse={false}></Appbar>

        <SectionHeader className="wow fadeInDown mt-12"></SectionHeader>

        <div className="mb-12">
          <SectionInformation></SectionInformation>
        </div>
      </div>

      <SectionDemo></SectionDemo>
    </div>
  );
}

function SectionInformation(props: {}) {
  return (
    <div className="text-left max-w-6xl mx-auto px-6">
      <Title title={utils.getLangByKey("faq_1_title")}></Title>
      <Card title={utils.getLangByKey("faq_1_q")}>
        <p className="text-sm">
          <span className="font-semibold">
            {utils.getLangByKey("faq_1_a_1")}
          </span>
          {utils.getLangByKey("faq_1_a_2")}
        </p>
      </Card>

      <div className="h-6"></div>
      <Title title={utils.getLangByKey("faq_2_title")}></Title>
      <Card title={utils.getLangByKey("faq_2_q")}>
        <p className="text-sm">
          <p className="font-semibold">{utils.getLangByKey("faq_2_a_a")}</p>
          <ol className="list-decimal ml-4 mt-1">
            <li>
              {utils.getLangByKey("faq_2_a_a_1_1")}
              <span className="font-semibold">
                {utils.getLangByKey("faq_2_a_a_1_2")}
              </span>
              {utils.getLangByKey("faq_2_a_a_1_3")}
            </li>
            <li>
              {utils.getLangByKey("faq_2_a_a_2_1")}
              <span className="font-semibold">
                {utils.getLangByKey("faq_2_a_a_2_2")}
              </span>
              {utils.getLangByKey("faq_2_a_a_2_3")}
              <span className="font-semibold">
                {utils.getLangByKey("faq_2_a_a_2_4")}
              </span>
            </li>
            <li>
              {utils.getLangByKey("faq_2_a_a_3_1")}
              <span className="font-semibold">
                {utils.getLangByKey("faq_2_a_a_3_2")}
              </span>
            </li>
            <li>
              {utils.getLangByKey("faq_2_a_a_4_1")}
              <span className="font-semibold">
                {utils.getLangByKey("faq_2_a_a_4_2")}
              </span>
            </li>
            <li>
              {utils.getLangByKey("faq_2_a_a_5_1")}
              <span className="font-semibold text-brand-dark">
                {utils.getLangByKey("faq_2_a_a_5_2")}
              </span>
              {utils.getLangByKey("faq_2_a_a_5_3")}
              <span className="font-semibold text-brand-dark">
                {utils.getLangByKey("faq_2_a_a_5_4")}
              </span>
              {utils.getLangByKey("faq_2_a_a_5_5")}
            </li>
            <li>
              {utils.getLangByKey("faq_2_a_a_6_1")}
              <span className="font-semibold">
                {utils.getLangByKey("faq_2_a_a_6_2")}
              </span>
              {utils.getLangByKey("faq_2_a_a_6_3")}
            </li>
            <li>
              {utils.getLangByKey("faq_2_a_a_7_1")}
              <span className="font-semibold">
                {utils.getLangByKey("faq_2_a_a_7_2")}
              </span>
              {utils.getLangByKey("faq_2_a_a_7_3")}
            </li>
          </ol>

          <p className="font-semibold mt-4">
            {utils.getLangByKey("faq_2_a_b")}
          </p>
          <ol className="list-decimal ml-4 mt-1">
            <li>
              {utils.getLangByKey("faq_2_a_b_1_1")}
              <span className="font-semibold">
                {utils.getLangByKey("faq_2_a_b_1_2")}
              </span>
              {utils.getLangByKey("faq_2_a_b_1_3")}
            </li>
            <li>{utils.getLangByKey("faq_2_a_b_2_1")}</li>
            <li>
              {utils.getLangByKey("faq_2_a_b_3_1")}
              <span className="font-semibold">
                {utils.getLangByKey("faq_2_a_b_3_2")}
              </span>
              {utils.getLangByKey("faq_2_a_b_3_3")}
            </li>
            <li>
              {utils.getLangByKey("faq_2_a_b_4_1")}
              <span className="font-semibold">
                {utils.getLangByKey("faq_2_a_b_4_2")}
              </span>
            </li>
            <li>
              {utils.getLangByKey("faq_2_a_b_5_1")}
              <span className="font-semibold">
                {utils.getLangByKey("faq_2_a_b_5_2")}
              </span>
              {utils.getLangByKey("faq_2_a_b_5_3")}
            </li>
            <li>
              {utils.getLangByKey("faq_2_a_b_6_1")}
              <span className="font-semibold">
                {utils.getLangByKey("faq_2_a_b_6_2")}
              </span>
              {utils.getLangByKey("faq_2_a_b_6_3")}
            </li>
          </ol>
        </p>
      </Card>

      <div className="h-6"></div>
      <Title title={utils.getLangByKey("faq_3_title")}></Title>
      <Card title={utils.getLangByKey("faq_3_q_1")}>
        <p className="text-sm">{utils.getLangByKey("faq_3_a_1")}</p>

        <InnerCard title={utils.getLangByKey("faq_3_q_2")}>
          <p className="text-sm">{utils.getLangByKey("faq_3_a_2")}</p>
        </InnerCard>
      </Card>

      <div className="h-6"></div>
      <Title title={utils.getLangByKey("faq_4_title")}></Title>
      <Card title={utils.getLangByKey("faq_4_q_1")}>
        <p className="text-sm">
          <p className="font-semibold">{utils.getLangByKey("faq_4_q_1_a")}</p>
          <ol className="list-decimal ml-4 mt-1">
            <li>
              <span className="font-semibold text-brand-dark">
                {utils.getLangByKey("faq_4_q_1_a_1_1")}
              </span>
              {utils.getLangByKey("faq_4_q_1_a_1_2")}
              <span className="font-semibold">
                {utils.getLangByKey("faq_4_q_1_a_1_3")}
              </span>
              {utils.getLangByKey("faq_4_q_1_a_1_4")}
              <span className="font-semibold">
                {utils.getLangByKey("faq_4_q_1_a_1_5")}
              </span>
              {utils.getLangByKey("faq_4_q_1_a_1_6")}
            </li>
            <li>{utils.getLangByKey("faq_4_q_1_a_2")}</li>
            <li>
              {utils.getLangByKey("faq_4_q_1_a_3_1")}
              <span className="font-semibold">
                {utils.getLangByKey("faq_4_q_1_a_3_2")}
              </span>
              {utils.getLangByKey("faq_4_q_1_a_3_3")}
            </li>
          </ol>

          <p className="font-semibold mt-4">
            {utils.getLangByKey("faq_4_q_1_b")}
          </p>
          <ol className="list-decimal ml-4 mt-1">
            <li>{utils.getLangByKey("faq_4_q_1_b_1")}</li>
            <li>
              {utils.getLangByKey("faq_4_q_1_b_2_1")}
              <span className="font-semibold">
                {utils.getLangByKey("faq_4_q_1_b_2_2")}
              </span>
              {utils.getLangByKey("faq_4_q_1_b_2_3")}
            </li>
            <li>
              {utils.getLangByKey("faq_4_q_1_b_3_1")}
              <span className="font-semibold">
                {utils.getLangByKey("faq_4_q_1_b_3_2")}
              </span>
              {utils.getLangByKey("faq_4_q_1_b_3_3")}
              <span className="font-semibold">
                {utils.getLangByKey("faq_4_q_1_b_3_4")}
              </span>
              {utils.getLangByKey("faq_4_q_1_b_3_5")}
              <span className="font-semibold">
                {utils.getLangByKey("faq_4_q_1_b_3_6")}
              </span>
              {utils.getLangByKey("faq_4_q_1_b_3_7")}
            </li>
            <li>
              {utils.getLangByKey("faq_4_q_1_b_4_1")}
              <span className="font-semibold">
                {utils.getLangByKey("faq_4_q_1_b_4_2")}
              </span>
              {utils.getLangByKey("faq_4_q_1_b_4_3")}
            </li>
          </ol>
          <p className="mt-3">
            {utils.getLangByKey("faq_4_i_1")}
            <span className="font-semibold">
              {utils.getLangByKey("faq_4_i_2")}
            </span>
            {utils.getLangByKey("faq_4_i_3")}
          </p>
        </p>

        <InnerCard title={utils.getLangByKey("faq_4_q_2")}>
          <p className="text-sm">{utils.getLangByKey("faq_4_q_2_a")}</p>
        </InnerCard>
      </Card>

      <div className="h-6"></div>
      <Title title={utils.getLangByKey("faq_5_title")}></Title>
      <Card title={utils.getLangByKey("faq_5_q_1")}>
        <p className="text-sm">
          <p className="mb-6">{utils.getLangByKey("faq_5_q_1_d")}</p>
          <p className="font-semibold">{utils.getLangByKey("faq_5_q_1_a")}</p>
          <ol className="list-decimal ml-4 mt-1">
            <li>
              <span className="font-semibold text-brand-dark">
                {utils.getLangByKey("faq_5_q_1_a_1_1")}
              </span>
              {utils.getLangByKey("faq_5_q_1_a_1_2")}
              <span className="font-semibold">
                {utils.getLangByKey("faq_5_q_1_a_1_3")}
              </span>
              {utils.getLangByKey("faq_5_q_1_a_1_4")}
              <span className="font-semibold">
                {utils.getLangByKey("faq_5_q_1_a_1_5")}
              </span>
              {utils.getLangByKey("faq_5_q_1_a_1_6")}
            </li>
            <li>{utils.getLangByKey("faq_5_q_1_a_2")}</li>
            <li>
              {utils.getLangByKey("faq_5_q_1_a_3_1")}
              <span className="font-semibold">
                {utils.getLangByKey("faq_5_q_1_a_3_2")}
              </span>
            </li>
            <li>{utils.getLangByKey("faq_5_q_1_a_4")}</li>
            <li>
              {utils.getLangByKey("faq_5_q_1_a_5_1")}
              <span className="font-semibold">
                {utils.getLangByKey("faq_5_q_1_a_5_2")}
              </span>
            </li>
          </ol>

          <p className="font-semibold mt-4">
            {utils.getLangByKey("faq_5_q_1_b")}
          </p>
          <ol className="list-decimal ml-4 mt-1">
            <li>{utils.getLangByKey("faq_5_q_1_b_1")}</li>
            <li>
              {utils.getLangByKey("faq_5_q_1_b_2_1")}
              <span className="font-semibold">
                {utils.getLangByKey("faq_5_q_1_b_2_2")}
              </span>
              {utils.getLangByKey("faq_5_q_1_b_2_3")}
            </li>
            <li>
              {utils.getLangByKey("faq_5_q_1_b_3_1")}
              <span className="font-semibold">
                {utils.getLangByKey("faq_5_q_1_b_3_2")}
              </span>
              {utils.getLangByKey("faq_5_q_1_b_3_3")}
              <span className="font-semibold">
                {utils.getLangByKey("faq_5_q_1_b_3_4")}
              </span>
              {utils.getLangByKey("faq_5_q_1_b_3_5")}
              <span className="font-semibold">
                {utils.getLangByKey("faq_5_q_1_b_3_6")}
              </span>
              {utils.getLangByKey("faq_5_q_1_b_3_7")}
            </li>
            <li>
              {utils.getLangByKey("faq_5_q_1_b_4_1")}
              <span className="font-semibold">
                {utils.getLangByKey("faq_5_q_1_b_4_2")}
              </span>
            </li>
            <li>{utils.getLangByKey("faq_5_q_1_b_5")}</li>
            <li>
              {utils.getLangByKey("faq_5_q_1_b_6_1")}
              <span className="font-semibold">
                {utils.getLangByKey("faq_5_q_1_b_6_2")}
              </span>
            </li>
          </ol>
        </p>

        <InnerCard title={utils.getLangByKey("faq_5_q_2")}>
          <p className="text-sm">
            <p className="mb-6">{utils.getLangByKey("faq_5_q_2_d")}</p>
            <p className="font-semibold">{utils.getLangByKey("faq_5_q_2_a")}</p>
            <ol className="list-decimal ml-4 mt-1">
              <li>
                <span className="font-semibold text-brand-dark">
                  {utils.getLangByKey("faq_5_q_2_a_1_1")}
                </span>
                {utils.getLangByKey("faq_5_q_2_a_1_2")}
                <span className="font-semibold">
                  {utils.getLangByKey("faq_5_q_2_a_1_3")}
                </span>
                {utils.getLangByKey("faq_5_q_2_a_1_4")}
                <span className="font-semibold">
                  {utils.getLangByKey("faq_5_q_2_a_1_5")}
                </span>
                {utils.getLangByKey("faq_5_q_2_a_1_6")}
              </li>
              <li>{utils.getLangByKey("faq_5_q_2_a_2")}</li>
              <li>
                {utils.getLangByKey("faq_5_q_2_a_3_1")}
                <span className="font-semibold">
                  {utils.getLangByKey("faq_5_q_2_a_3_2")}
                </span>
                .
              </li>
              <li>{utils.getLangByKey("faq_5_q_2_a_4_1")}</li>
              <li>
                {utils.getLangByKey("faq_5_q_2_a_5_1")}
                <span className="font-semibold">
                  {utils.getLangByKey("faq_5_q_2_a_5_2")}
                </span>
                .
              </li>
            </ol>

            <p className="font-semibold mt-4">
              {utils.getLangByKey("faq_5_q_2_b")}
            </p>
            <ol className="list-decimal ml-4 mt-1">
              <li>{utils.getLangByKey("faq_5_q_2_b_1")}</li>
              <li>
                {utils.getLangByKey("faq_5_q_2_b_2_1")}
                <span className="font-semibold">
                  {utils.getLangByKey("faq_5_q_2_b_2_2")}
                </span>
                {utils.getLangByKey("faq_5_q_2_b_2_3")}
              </li>
              <li>
                {utils.getLangByKey("faq_5_q_2_b_3_1")}
                <span className="font-semibold">
                  {utils.getLangByKey("faq_5_q_2_b_3_2")}
                </span>
                {utils.getLangByKey("faq_5_q_2_b_3_3")}
                <span className="font-semibold">
                  {utils.getLangByKey("faq_5_q_2_b_3_4")}
                </span>
                {utils.getLangByKey("faq_5_q_2_b_3_5")}
                <span className="font-semibold">
                  {utils.getLangByKey("faq_5_q_2_b_3_6")}
                </span>
                {utils.getLangByKey("faq_5_q_2_b_3_7")}
              </li>
              <li>
                {utils.getLangByKey("faq_5_q_2_b_4_1")}
                <span className="font-semibold">
                  {utils.getLangByKey("faq_5_q_2_b_4_2")}
                </span>
              </li>
              <li>{utils.getLangByKey("faq_5_q_2_b_5")}</li>
              <li>
                {utils.getLangByKey("faq_5_q_2_b_6_1")}
                <span className="font-semibold">
                  {utils.getLangByKey("faq_5_q_2_b_6_2")}
                </span>
              </li>
            </ol>
            <p className="mt-3">{utils.getLangByKey("faq_5_q_2_i")}</p>
          </p>
        </InnerCard>

        <InnerCard title={utils.getLangByKey("faq_5_q_3")}>
          <p className="text-sm">
            <p>{utils.getLangByKey("faq_5_q_3_a")}</p>
          </p>
        </InnerCard>

        <InnerCard title={utils.getLangByKey("faq_5_q_4")}>
          <p className="text-sm">
            <p className="mb-6">
              <span className="font-semibold">
                {utils.getLangByKey("faq_5_q_4_d_1")}
              </span>
              {utils.getLangByKey("faq_5_q_4_d_2")}
            </p>
            <p className="font-semibold">{utils.getLangByKey("faq_5_q_4_a")}</p>
            <ol className="list-decimal ml-4 mt-1">
              <li>
                <span className="font-semibold text-brand-dark">
                  {utils.getLangByKey("faq_5_q_4_a_1_1")}
                </span>
                {utils.getLangByKey("faq_5_q_4_a_1_2")}
                <span className="font-semibold">
                  {utils.getLangByKey("faq_5_q_4_a_1_3")}
                </span>
                {utils.getLangByKey("faq_5_q_4_a_1_4")}
                <span className="font-semibold">
                  {utils.getLangByKey("faq_5_q_4_a_1_5")}
                </span>
                {utils.getLangByKey("faq_5_q_4_a_1_6")}
              </li>
              <li>{utils.getLangByKey("faq_5_q_4_a_2")}</li>
              <li>
                {utils.getLangByKey("faq_5_q_4_a_3_1")}
                <span className="font-semibold">
                  {utils.getLangByKey("faq_5_q_4_a_3_2")}
                </span>
                {utils.getLangByKey("faq_5_q_4_a_3_3")}
              </li>
              <li>{utils.getLangByKey("faq_5_q_4_a_4")}</li>
              <li>
                {utils.getLangByKey("faq_5_q_4_a_5_1")}
                <span className="font-semibold">
                  {utils.getLangByKey("faq_5_q_4_a_5_2")}
                </span>
                {utils.getLangByKey("faq_5_q_4_a_5_3")}
              </li>
              <li>
                {utils.getLangByKey("faq_5_q_4_a_6_1")}
                <span className="font-semibold">
                  {utils.getLangByKey("faq_5_q_4_a_6_2")}
                </span>
              </li>
            </ol>

            <p className="font-semibold mt-4">
              {utils.getLangByKey("faq_5_q_4_b")}
            </p>
            <ol className="list-decimal ml-4 mt-1">
              <li>{utils.getLangByKey("faq_5_q_4_b_1")}</li>
              <li>
                {utils.getLangByKey("faq_5_q_4_b_2_1")}
                <span className="font-semibold">
                  {utils.getLangByKey("faq_5_q_4_b_2_2")}
                </span>
                {utils.getLangByKey("faq_5_q_4_b_2_3")}
              </li>
              <li>
                {utils.getLangByKey("faq_5_q_4_b_3_1")}
                <span className="font-semibold">
                  {utils.getLangByKey("faq_5_q_4_b_3_2")}
                </span>
                {utils.getLangByKey("faq_5_q_4_b_3_3")}
                <span className="font-semibold">
                  {utils.getLangByKey("faq_5_q_4_b_3_4")}
                </span>
                {utils.getLangByKey("faq_5_q_4_b_3_5")}
                <span className="font-semibold">
                  {utils.getLangByKey("faq_5_q_4_b_3_6")}
                </span>
                {utils.getLangByKey("faq_5_q_4_b_3_7")}
              </li>
              <li>
                {utils.getLangByKey("faq_5_q_4_b_4_1")}
                <span className="font-semibold">
                  {utils.getLangByKey("faq_5_q_4_b_4_2")}
                </span>
                {utils.getLangByKey("faq_5_q_4_b_4_3")}
              </li>
              <li>
                {utils.getLangByKey("faq_5_q_4_b_5_1")}
                <span className="font-semibold">
                  {utils.getLangByKey("faq_5_q_4_b_5_2")}
                </span>
                {utils.getLangByKey("faq_5_q_4_b_5_3")}
              </li>
              <li>
                {utils.getLangByKey("faq_5_q_4_b_6_1")}
                <span className="font-semibold">
                  {utils.getLangByKey("faq_5_q_4_b_6_1")}
                </span>
              </li>
            </ol>
            <p className="mt-3">{utils.getLangByKey("faq_5_q_4_i")}</p>
          </p>
        </InnerCard>
      </Card>
    </div>
  );
}

function InnerCard(props: { title: string; children: any }) {
  const [isOpen, setOpen] = useState(true);

  return (
    <div className="pt-8 bg-white rounded-lg">
      <div className="border-t pt-6"></div>
      <div
        className="flex justify-between cursor-pointer select-none"
        onClick={() => setOpen(!isOpen)}
      >
        <div className="font-medium text-lg">{props.title}</div>
        <div className={`${isOpen ? "rotate-180" : ""}`}>
          <ArrowDown2 size={24} variant="Linear"></ArrowDown2>
        </div>
      </div>
      <div className={isOpen ? "" : "h-0 overflow-hidden"}>
        <div className="mt-4">{props.children}</div>
      </div>
    </div>
  );
}

function Card(props: { title: string; children: any }) {
  const [isOpen, setOpen] = useState(true);

  return (
    <div className="p-8 bg-white rounded-lg">
      <div
        className="flex justify-between cursor-pointer select-none"
        onClick={() => setOpen(!isOpen)}
      >
        <div className="font-medium text-lg">{props.title}</div>
        <div className={`${isOpen ? "rotate-180" : ""}`}>
          <ArrowDown2 size={24} variant="Linear"></ArrowDown2>
        </div>
      </div>
      <div className={isOpen ? "" : "h-0 overflow-hidden"}>
        <div className="pb-6 border-b mt-4">{props.children}</div>
      </div>
    </div>
  );
}

function Title(props: { title: string }) {
  return <div className="font-medium text-xl mb-4">{props.title}</div>;
}

function SectionHeader(props: { className?: string }) {
  return (
    <section className={`${props.className}`}>
      <article>
        <div className="max-w-7xl mx-auto px-6 py-8">
          <h1 className="text-4xl p-0 text-center !leading-normal font-semibold md:text-5xl">
            Frequently Asked Questions (FAQs)
          </h1>
          {/* <input
                        className="w-full p-4 text-gray-900 rounded-lg bg-white border focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Search topics or questions"
                    /> */}
        </div>
      </article>
    </section>
  );
}
