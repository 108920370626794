import config from "Config";

export const reasons = [
	{
		imgUrl: `${config.AWS_URL_IMAGES_LANDING}/images/specialist/bg_reason1.png`,
		title: "Support Community",
		desc: "Meet and grow with a community that shares the same passion in elevating the mental wellbeing of our workplace.",
	},
	{
		imgUrl: `${config.AWS_URL_IMAGES_LANDING}/images/specialist/bg_reason2.png`,
		title: "Flexible Arrangement",
		desc: "Set your schedule based on your availability. Our calendar integrates with your google calendar for convenience.",
	},
	{
		imgUrl: `${config.AWS_URL_IMAGES_LANDING}/images/specialist/bg_reason3.png`,
		title: "Growth Workshop",
		desc: "Be invited to workshops led by experienced mental health practitioners to improve the care that you deliver.",
	},
];

export const topicExpertise = [
	"Business",
	"Career",
	"Colleagues",
	"Diversity",
	"Emotions",
	"Family",
	"Friends",
	"Health",
	"Leadership",
	"Management",
	"Money",
	"Personal Growth",
	"Self Love",
	"Partner",
];

export const yearExperience = [
	"Under 3 Years",
	"3 to 5 Years",
	"5 to 10 Years",
	"Over 10 Years",
];
