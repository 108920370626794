import config from "Config";

export const testimony = [
	{
		image: `"${config.AWS_URL_IMAGES_LANDING}/images/customer-logo/Brandt International.png`,
		name: "Andrew Chong",
		position: "Chief Operating Officer, Brandt International",
		subtitle: `"Maxi's focus is in a critical area that is key to a successful, healthy and productive working environment"`,
		companyIndustry: "Information Technology And Services",
	},
	{
		image: `"${config.AWS_URL_IMAGES_LANDING}/images/customer-logo/DJI.png`,
		name: "Harry Fang",
		position: "Chief Executive Officer, Alfatech (DJI)",
		subtitle: `"I personally think Maxi is a great idea, can't wait for it to roll out and hope it will be the next life channger for Indonesians."`,
		companyIndustry: "Construction",
	},
	{
		image: `${config.AWS_URL_IMAGES_LANDING}/images/customer-logo/Sekolah Lentera Kasih.png`,
		name: "Jeremy Widjaja",
		position: "Director, Sekolah Lentera Kasih",
		subtitle: `"Maxi is a refreshing solution for productivity of our staffs. I also believe that it can be beneficial for students"`,
		companyIndustry: "Education Management",
	},
];
