import { langData } from "Constant/lang";
import { getLangCookies } from "Helper/localStorage";

interface IMonth {
  monthNumber: number;
  monthName: string;
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const getMonths = () => {
  return months.map<IMonth>((val, i, _) => {
    return {
      monthName: val,
      monthNumber: i + 1,
    };
  });
};

const getYears = () => {
  const curYear = new Date().getFullYear();
  const years: number[] = [];

  for (let year = 2021; year <= curYear; year++) {
    years.push(year);
  }

  return years;
};

const onlyAlphanum = (text?: string) => {
  if (!text) {
    return "";
  }

  return text.replace(/[^0-9a-zA-Z ]/g, "");
};

const getLangActive = () => {
  const activeLang = getLangCookies();
  if (activeLang) {
    return activeLang;
  } else {
    return "ID";
  }
};

const getLangByKey = (keySearch: string) => {
  const activeLang = getLangCookies();
  if (activeLang) {
    if (activeLang === "EN") {
      if (langData.find((v) => v.key === keySearch)) {
        return langData.find((v) => v.key === keySearch)!.valueEn;
      } else {
        return keySearch;
      }
    } else {
      if (langData.find((v) => v.key === keySearch)) {
        return langData.find((v) => v.key === keySearch)!.valueId;
      } else {
        return keySearch;
      }
    }
  } else {
    if (langData.find((v) => v.key === keySearch)) {
      return langData.find((v) => v.key === keySearch)!.valueId;
    } else {
      return keySearch;
    }
  }
};
function getOS() {
  const userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ["macOS", "Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"];
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac OS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (/Linux/.test(platform)) {
    os = "Linux";
  }

  console.log("OS :" + os);
  return os;
}

const openDownloadWithAgent = () => {
  var _os = getOS();
  if (_os === "Mac OS" || _os === "iOS") {
    window.open(
      "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.rebelworks.maxi_app_prod",
      "_blank"
    );
  } else if (_os === "Windows" || _os === "Android") {
    window.open(
      "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.rebelworks.maxi_app_prod",
      "_blank"
    );
  } else {
    window.open(
      "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.rebelworks.maxi_app_prod",
      "_blank"
    );
  }
};

const utils = {
  getMonths,
  getYears,
  onlyAlphanum,
  getLangActive,
  getLangByKey,
  openDownloadWithAgent,
};

export default utils;
