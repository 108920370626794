import axios from "axios";
import config from "Config";
import { getTokenAuth } from "Helper/localStorage";

export const api = axios.create({
	baseURL: config.API_URL,
	headers: {
		"Content-type": "application/json; charset=UTF-8",
		"X-Channel": "WEB",
		"X-TIMEZONE": config.timezoneOffsetHour,
	},
});

export const apiMobile = axios.create({
	baseURL: config.API_MOBILE_URL,
	headers: {
		"Content-type": "application/json; charset=UTF-8",
		"X-Channel": "app",
		"X-TIMEZONE": config.timezoneOffsetHour,
	},
});

// Add a request interceptor
apiMobile.interceptors.request.use(async (req) => {
	const token = getTokenAuth();
	req.headers!.Authorization = token!;

	return req;
});

apiMobile.interceptors.response.use(
	(val) => val,
	(error) => {
		let message = error.response.data.stat_msg;
		if (!message) {
			message = error.toString();
		}

		return Promise.reject(error);
	}
);


// Add a request interceptor
api.interceptors.request.use(async (req) => {
	const token = getTokenAuth();
	req.headers!.Authorization = token!;

	return req;
});

api.interceptors.response.use(
	(val) => val,
	(error) => {
		let message = error.response.data.stat_msg;
		if (!message) {
			message = error.toString();
		}

		return Promise.reject(message);
	}
);
