import { ScrollToTop } from "Component/ScrollTop";
import { useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RoutePage from "Route";
import "./App.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { getProfile } from "Service/profile";
import { userDataAtom } from "State";
import { useAtom } from "jotai";

const WOW = require("wow.js");
const queryClient = new QueryClient();

export default function App() {
  const [_, setUserData] = useAtom(userDataAtom);

  useEffect(() => {
    getProfile().then((val) => {
      const data = val.data;

      setUserData({
        username: data.username,
        email: data.email,
        profile_url: data.profile_url,
        role_name: data.role_name,
        timezone: data.timezone,
      });
    });

    new WOW().init();
  }, []);

  return (
    <div className="App">
      <ScrollToTop />
      <ToastContainer />
      <QueryClientProvider client={queryClient}>
        <RoutePage></RoutePage>
      </QueryClientProvider>
    </div>
  );
}
