import Appbar from 'Component/Appbar';
import FloatingButton from 'Component/FloatingChat';
import { SectionDemo } from 'Component/Footer';
import { CheckField, InputField, SelectField } from 'Component/Input';
import { LoadingModal } from "Component/LoadingModal";
import config from 'Config';
import { onChat } from 'Helper';
import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { bookApp, getListIndustry } from 'Service';
import { employeeRoles, listNumEmployee } from "./Content";

export default function BookPage() {
    return (
        <div className="text-center">
            <FloatingButton onChat={ onChat }></FloatingButton>

            <div style={ { backgroundImage: `url('${config.AWS_URL_IMAGES_LANDING}/images/employer/bg_home.png')`, backgroundSize: 'cover', backgroundPosition: 'center top' } }>
                <Appbar isReverse={ false }></Appbar>
                <SectionHeader className="wow fadeInDown"></SectionHeader>
            </div>

            <SectionDemo></SectionDemo>
        </div>
    );
}

function SectionHeader(props: { className?: string; }) {
    return (
        <section className={ `${props.className}` }>
            <article>
                <div className="max-w-7xl mx-auto px-6 lg:py-28 py-8 lg:flex">
                    <div className='lg:basis-2/5 mb-24 lg:mb-0 lg:pr-6'>
                        <h1 className='text-4xl p-0 max-w-lg leading-tight font-semibold text-left md:text-5xl'>
                            Start your journey today
                        </h1>
                        <p className='max-w-lg md:mt-3 my-8 text-left opacity-80'>It is time to elevate mental wellbeing care in your workplace. Whether you are an employer, employee or specialist, you can contribute in a big way. Request a demo and begin the journey today.</p>
                        <img src={ `${config.AWS_URL_IMAGES_LANDING}/images/book/bg_book.png` } alt="" />
                    </div>
                    <div className="lg:basis-3/5">
                        <SectionForm></SectionForm>
                    </div>
                </div>
            </article>
        </section>
    );
}

function SectionForm() {
    const navigate = useNavigate();
    const { register, handleSubmit } = useForm();
    const [userType, setUserType] = useState('');
    const [other, setOther] = useState('');
    const [listIndustry, setListIndustry] = useState<any[]>([]);
    const [isSendEmail, setSendEmail] = useState(false);

    const isEnable = isSendEmail ? false : userType !== '';

    const ItemType = (props: { title: string, desc: string, className?: string, onClick?: () => void; }) => {
        return (
            <div className={ `bg-slate-100 rounded-md text-left px-4 py-6 ${props.className}` } onClick={ props.onClick }>
                <div className='font-semibold text-lg mb-2'>{ props.title }</div>
                <div className='opacity-60'>{ props.desc }</div>
            </div>
        );
    };

    const onChangeUserType = (val: string) => {
        if (val === userType) {
            setUserType('');
        } else {
            setUserType(val);
        }
    };

    const onSubmit = async (val: any) => {
        if (other !== '') {
            const sources = val.sources as string[];
            const length = sources.length;
            for (let i = 0; i < length; i++) {
                if (sources[i] === 'Other') {
                    sources[i] = other;
                    break;
                }
            }
        }

        setSendEmail(true);

        await bookApp({
            ...val,
            role: userType,
            sources: (val.sources as string[]).join(", ")
        });

        setSendEmail(false);
        navigate('/booked');
    };

    useEffect(() => {
        getListIndustry()
            .then((val) => {
                setListIndustry(val.data);
            });
    }, []);

    return (
        <>
            <LoadingModal show={ isSendEmail }>
                Send Email...
            </LoadingModal>
            <div className='lg:bg-white rounded-md lg:p-8 text-left'>
                <div className='font-bold text-2xl mb-4'>Book a Demo</div>
                <div className='font-semibold mb-6'>Tell us more about yourself.</div>
                <div className='flex flex-col lg:flex-row gap-8'>
                    {
                        employeeRoles.map((val, i) => {
                            return (
                                <ItemType key={ i } { ...val }
                                    className={ `cursor-pointer ${userType === val.id ? 'bg-blue-app/30' : ''}` }
                                    onClick={ () => onChangeUserType(val.id) }
                                ></ItemType>
                            );
                        })
                    }
                </div>

                <form onSubmit={ handleSubmit(onSubmit) }>
                    <InputField
                        className='my-6'
                        label='Email Address'
                        placeholder='Input Email'
                        type='email'
                        { ...register("email", { required: true, pattern: /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/i }) }
                    ></InputField>
                    <InputField
                        className='my-6'
                        label='Mobile Number'
                        placeholder='Input Mobile Number'
                        type='number'
                        { ...register("phone", { required: true }) }
                    ></InputField>
                    <InputField
                        className='my-6'
                        label='Company Name (If Applicable)'
                        placeholder='Input Company Name'
                        { ...register("company_name") }
                    ></InputField>
                    <SelectField
                        defaultValue=''
                        className='my-6'
                        label='Number of Employees (If Applicable)'
                        { ...register("num_employee") }
                    >
                        <option value="" disabled>Choose Number of Employees</option>
                        {
                            listNumEmployee.map((val, i) => {
                                return (
                                    <option key={ i } value={ val }>{ val }</option>
                                );
                            })
                        }
                    </SelectField>
                    <SelectField
                        defaultValue=''
                        className='my-6'
                        label='Industry (If Applicable)'
                        { ...register("industry") }
                    >
                        <option value="" disabled>Choose Industry</option>
                        {
                            listIndustry.map((val, i) => {
                                return (
                                    <option key={ i } value={ val.set_key }>{ val.set_label }</option>
                                );
                            })
                        }
                    </SelectField>

                    <div className='font-semibold mb-3'>How did you hear about us?</div>
                    <div className='text-sm opacity-60'>Tick one or more</div>
                    <div className='flex flex-wrap gap-4 mt-4'>
                        <CheckField value='Intagram' { ...register('sources', { required: true }) } label='Instagram'></CheckField>
                        <CheckField value='Linkedln' { ...register('sources', { required: true }) } label='Linkedln'></CheckField>
                        <CheckField value='Google Search' { ...register('sources', { required: true }) } label='Google Search'></CheckField>
                        <CheckField value='Press Release' { ...register('sources', { required: true }) } label='Press Release'></CheckField>
                    </div>
                    <div>
                        <div className='flex mt-4 gap-4'>
                            <CheckField value='Other' { ...register('sources', { required: true }) } className='mt-1 shrink-0' label='Other'></CheckField>
                            <InputField
                                inputclassname='py-1'
                                placeholder='Other'
                                onChange={ (val) => setOther(val.target.value) }
                            ></InputField>
                        </div>
                    </div>

                    <input disabled={ !isEnable } type="submit" className={ `mt-8 w-full px-6 py-3 rounded-lg bg-black-app cursor-pointer leading-6 font-bold text-white text-center ${isEnable ? '' : 'bg-black-app/25 cursor-not-allowed'}` } value='Book a Demo' />
                </form>
            </div>
        </>
    );
}
