import { onChat } from 'Helper';
import { Link } from 'react-router-dom';

export function ButtonFilled(props: { children: any; }) {
    return (
        <div className='mb-4 sm:mb-0 px-6 py-3 rounded-lg leading-6 font-bold text-white bg-black-app cursor-pointer'>
            { props.children }
        </div>
    );
}

export function ButtonBookDemo() {
    return (
        <Link to='/book'>
            <div className='mb-4 sm:mb-0 px-6 py-3 rounded-lg leading-6 font-bold text-white bg-black-app cursor-pointer'>
                Book Demo
            </div>
        </Link>
    );
}

export function ButtonContactUs() {
    return (
        <div className='mb-4 sm:mb-0 px-6 py-3 rounded-lg leading-6 font-bold text-white bg-black-app cursor-pointer' onClick={ onChat }>
            Contact Us
        </div>
    );
}

export function ButtonJoinSpecialist() {
    // return (
    //     <Link to='/specialist?joinform=true'>
    //         <div className='mb-4 sm:mb-0 px-6 py-3 rounded-lg leading-6 font-bold text-white bg-black-app cursor-pointer'>
    //             Join as Specialist
    //         </div>
    //     </Link>
    // );
    return (
        <a href='https://bit.ly/MaxiSpecialist' target="_blank" rel="noopener noreferrer">
            <div className='mb-4 sm:mb-0 px-6 py-3 rounded-lg leading-6 font-bold text-white bg-black-app cursor-pointer'>
                Join as Specialist
            </div>
        </a>
    );
}
