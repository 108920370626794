import IconSearch from "Images/Svg/icon_search.svg";
import IconUpload from "Images/Svg/icon_upload.svg";
import React, { InputHTMLAttributes, useState } from "react";
import { UseFormRegister } from "react-hook-form";

interface IInputField extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  inputclassname?: string;
}

interface ISelectField extends InputHTMLAttributes<HTMLSelectElement> {
  label?: string;
  inputclassname?: string;
}

interface ICheckField extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  inputclassname?: string;
}

export const SelectField = React.forwardRef((props: ISelectField, ref: any) => {
  const forId = generateID();

  return (
    <div className={props.className}>
      <label
        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
        htmlFor={forId}
      >
        {props.label}
      </label>
      <select
        {...props}
        className={`${props.inputclassname}
                block appearance-none w-full bg-gray-100 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500
            `}
        id={forId}
        ref={ref}
      >
        {props.children}
      </select>
    </div>
  );
});

export const InputField = React.forwardRef((props: IInputField, ref: any) => {
  const forId = generateID();

  return (
    <div className={props.className}>
      {props.label ? (
        <label
          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
          htmlFor={forId}
        >
          {props.label}
        </label>
      ) : null}
      <input
        {...props}
        className={`${props.inputclassname ?? ""}
                appearance-none block w-full bg-gray-100 text-gray-700 border-none rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white
            `}
        id={forId}
        type="text"
        placeholder={props.placeholder}
        ref={ref}
      ></input>
    </div>
  );
});

export const EmailField = React.forwardRef((props: IInputField, ref: any) => {
  const forId = generateID();

  return (
    <div className={props.className}>
      {props.label ? (
        <label
          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
          htmlFor={forId}
        >
          {props.label}
        </label>
      ) : null}
      <input
        {...props}
        className={`${props.inputclassname ?? ""}
                appearance-none block w-full bg-gray-100 text-gray-700 border-none rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white
            `}
        id={forId}
        type="email"
        placeholder={props.placeholder}
        ref={ref}
      ></input>
    </div>
  );
});

export const CheckField = React.forwardRef((props: ICheckField, ref: any) => {
  const forId = generateID();

  return (
    <div className={props.className}>
      <input
        {...props}
        ref={ref}
        id={forId}
        type="checkbox"
        value={props.value}
        className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
      />
      <label
        htmlFor={forId}
        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
      >
        {props.label}
      </label>
    </div>
  );
});

export const RadioField = React.forwardRef((props: ICheckField, ref: any) => {
  const forId = generateID();

  return (
    <div className={props.className}>
      <input
        {...props}
        ref={ref}
        id={forId}
        type="radio"
        value={props.value}
        className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
      />
      <label
        htmlFor={forId}
        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
      >
        {props.label}
      </label>
    </div>
  );
});

export const SearchField = React.forwardRef(
  (props: InputHTMLAttributes<HTMLInputElement>, ref: any) => {
    return (
      <div className="relative">
        <div className="flex absolute inset-y-0 left-0 items-center pl-3">
          <img src={IconSearch} alt="" />
        </div>
        <input
          {...props}
          ref={ref}
          type="search"
          id="search"
          className={`
                block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500
                ${props.className ?? ""}
            `}
          placeholder={props.placeholder ?? "Search"}
        />
      </div>
    );
  }
);

export const FileField = (props: {
  register: UseFormRegister<any>;
  name: string;
  accept: string;
}) => {
  const { onChange, ref } = props.register(props.name);
  const [file, setFile] = useState<File | null>(null);
  const idName = Math.random().toString();

  const onClick = () => {
    document.getElementById(idName)?.click();
  };

  return (
    <>
      <div
        className={`
                    py-8 rounded-lg border border-dashed text-center cursor-pointer
                    ${
                      file
                        ? "border-green-500 bg-green-100 text-green-500"
                        : "border-blue-app bg-blue-background text-blue-app"
                    }
                `}
        onClick={onClick}
      >
        <div>
          {file ? (
            <h2>{file.name}</h2>
          ) : (
            <img className="mx-auto" src={IconUpload} alt="" />
          )}
        </div>
        <div>{file ? "Update File" : "Add File"}</div>
      </div>
      <input
        ref={ref}
        id={idName}
        type="file"
        accept={props.accept}
        onChange={onChange}
        className="hidden"
      />
    </>
  );
};

function generateID() {
  return Math.random().toString().substring(2);
}
