import { api } from "./api";

export async function getListIndustry() {
	const resp = await api.get("/v1/landing/industry", {
		params: {
			is_active: true,
			set_group: "industry",
			sort: "asc",
			order: "content_value",
			limit: 100,
			page: 1,
		},
	});

	return resp.data;
}

export const bookApp = async (data: any) => {
	const resp = await api.post("/v1/landing/book", data);

	return resp.data;
};

export const uploadFile = async (file: File) => {
	const form = new FormData();
	form.append("uploadfile", file);

	const resp = await api.post("/v1/landing/upload", form);

	return resp.data;
};

export const specialistJoin = async (data: any) => {
	const resp = await api.post("/v1/landing/join_as_specialist", data);

	return resp.data;
};
