import config from "Config";

export const adopters = [
	{
		image: `${config.AWS_URL_IMAGES_LANDING}/images/customer-logo/Altech Omega.png`,
		label: "Altech Omega",
	},
	{
		image: `${config.AWS_URL_IMAGES_LANDING}/images/customer-logo/Amanco.png`,
		label: "Amanco",
	},
	{
		image: `${config.AWS_URL_IMAGES_LANDING}/images/customer-logo/Apiary.png`,
		label: "AApiary",
	},
	{
		image: `${config.AWS_URL_IMAGES_LANDING}/images/customer-logo/Attn.png`,
		label: "ATTN",
	},
	{
		image: `${config.AWS_URL_IMAGES_LANDING}/images/customer-logo/B Quik.png`,
		label: "B-Quik",
	},
	{
		image: `${config.AWS_URL_IMAGES_LANDING}/images/customer-logo/Bank Sampoerna.png`,
		label: "Bank Sampoerna",
	},
	{
		image: `${config.AWS_URL_IMAGES_LANDING}/images/customer-logo/Brandt International.png`,
		label: "Brandt International",
	},
	{
		image: `${config.AWS_URL_IMAGES_LANDING}/images/customer-logo/DJI.png`,
		label: "Altech Omega",
	},
	{
		image: `${config.AWS_URL_IMAGES_LANDING}/images/customer-logo/Duraquipt Cemerlang Perkasa.png`,
		label: "Altech Omega",
	},
	{
		image: `${config.AWS_URL_IMAGES_LANDING}/images/customer-logo/Eranya Cloud.png`,
		label: "Altech Omega",
	},
	{
		image: `${config.AWS_URL_IMAGES_LANDING}/images/customer-logo/EVOS.png`,
		label: "Altech Omega",
	},
	{
		image: `${config.AWS_URL_IMAGES_LANDING}/images/customer-logo/Isla.png`,
		label: "",
	},
	{
		image: `${config.AWS_URL_IMAGES_LANDING}/images/customer-logo/Kaliber.png`,
		label: "",
	},
	{
		image: `${config.AWS_URL_IMAGES_LANDING}/images/customer-logo/Keyta.png`,
		label: "",
	},
	{
		image: `${config.AWS_URL_IMAGES_LANDING}/images/customer-logo/Mary Gops 360.png`,
		label: "",
	},
	{
		image: `${config.AWS_URL_IMAGES_LANDING}/images/customer-logo/Sekolah Lentera Kasih.png`,
		label: "",
	},
	{
		image: `${config.AWS_URL_IMAGES_LANDING}/images/customer-logo/Smart Tech Solutions.png`,
		label: "",
	},
	{
		image: `${config.AWS_URL_IMAGES_LANDING}/images/customer-logo/WeNetwork.png`,
		label: "",
	},
	{
		image: `${config.AWS_URL_IMAGES_LANDING}/images/customer-logo/Whim.png`,
		label: "",
	},
];

export const testimony = [
	{
		image: `${config.AWS_URL_IMAGES_LANDING}/images/customer-logo/Brandt International.png`,
		name: "Andrew Chong",
		position: "Chief Operating Officer, Brandt International",
		subtitle: `"Maxi's focus is in a critical area that is key to a successful, healthy and productive working environment"`,
		companyIndustry: "Information Technology And Services",
	},
	{
		image: `${config.AWS_URL_IMAGES_LANDING}/images/customer-logo/DJI.png`,
		name: "Harry Fang",
		position: "Chief Executive Officer, Alfatech (DJI)",
		subtitle: `"I personally think Maxi is a great idea, can't wait for it to roll out and hope it will be the next life channger for Indonesians."`,
		companyIndustry: "Construction",
	},
	{
		image: `${config.AWS_URL_IMAGES_LANDING}/images/customer-logo/Sekolah Lentera Kasih.png`,
		name: "Jeremy Widjaja",
		position: "Director, Sekolah Lentera Kasih",
		subtitle: `"Maxi is a refreshing solution for productivity of our staffs. I also believe that it can be beneficial for students"`,
		companyIndustry: "Education Management",
	},
];

export const personalCares = [
	{
		title: "Personalised Care",
		subtitle:
			"Maxi is an all-in-one wellbeing platform that keeps employees happy and motivated.",
		imageUrl: "",
	},
	{
		title: "1:1 Counseling",
		subtitle:
			"Connecting employees with trained counselors, licensed psychologists and certified coaches.",
		imageUrl: `${config.AWS_URL_IMAGES_LANDING}/images/home/bg_care1.png`,
	},
	{
		title: "Crisis Support",
		subtitle:
			"Supporting employees to get through their most challenging moments with our messaging hotline service.",
		imageUrl: `${config.AWS_URL_IMAGES_LANDING}/images/home/bg_care2.png`,
	},
	{
		title: "Community",
		subtitle:
			"Fostering connection and support through expert-led forum discussions on worklife challenges.",
		imageUrl: `${config.AWS_URL_IMAGES_LANDING}/images/home/bg_care3.png`,
	},
];
