import Container from "Component/Container";
import { Footer } from "Component/Footer";
import { LoadingModal } from "Component/LoadingModal";
import config from "Config";
import BookPage from "Pages/Book";
import BookedPage from "Pages/Booked";
import Company from "Pages/Company";
import EmployeePage from "Pages/Employee";
import EmployerPage from "Pages/Employer";
import Faq from "Pages/Faq";
import LoginPage from "Pages/Login";
import SpecialistPage from "Pages/Specialist";
import OldHome from "Pages/_Home";
import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

const About = lazy(() => import("Pages/About"));
const Home = lazy(() => import("Pages/Home"));
const Linker = lazy(() => import("Pages/Linker/Linker"));
const Privacy = lazy(() => import("Pages/Privacy/Privacy"));
const FindSpecialist = lazy(() => import("Pages/FindSpecialist"));
const Term = lazy(() => import("Pages/Term/Term"));
const Matched = lazy(() => import("Pages/Matched"));

const isDebug = !config.isProduction;

export default function RoutePage() {
  return (
    <Suspense fallback={<LoadingModal></LoadingModal>}>
      <Routes>
        <Route
          path="/"
          element={
            <Container footer={<Footer></Footer>}>
              <Home />
            </Container>
          }
        />
        <Route
          path="/team"
          element={
            <Container footer={<Footer></Footer>}>
              <Company />
            </Container>
          }
        />
        <Route
          path="/booking/*"
          element={
            <Container footer={<Footer></Footer>}>
              <FindSpecialist />
            </Container>
          }
        />
        <Route path="/booked" element={<BookedPage />} />
        <Route
          path="/about"
          element={
            <Container footer={<Footer></Footer>}>
              <About />
            </Container>
          }
        />
        <Route
          path="/get_matched"
          element={
            <Container footer={<Footer></Footer>}>
              <Matched />
            </Container>
          }
        />
        <Route
          path="/privacy"
          element={
            <Container footer={<Footer></Footer>}>
              <Privacy />
            </Container>
          }
        />
        <Route
          path="/term"
          element={
            <Container footer={<Footer></Footer>}>
              <Term />
            </Container>
          }
        />
        <Route
          path="/faq"
          element={
            <Container footer={<Footer></Footer>}>
              <Faq />
            </Container>
          }
        />
        <Route path="/download-now" element={<Linker />} />
        <Route path="/empty" element={<p>Internal Page</p>}></Route>

        {isDebug ? (
          <>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/employee" element={<EmployeePage />} />
            <Route path="/employer" element={<EmployerPage />} />
            <Route path="/book" element={<BookPage />} />
            <Route path="/specialist" element={<SpecialistPage />} />
            <Route path="/_home" element={<OldHome />} />
          </>
        ) : null}
      </Routes>
    </Suspense>
  );
}
