import { Lang } from "types/lang";

export const langData: Lang[] = [
  //Global
  {
    key: "global_lang_id",
    valueId: "Bahasa Indonesia",
    valueEn: "Indonesian",
  },
  {
    key: "global_lang_en",
    valueId: "Bahasa Inggris",
    valueEn: "English",
  },
  {
    key: "global_download_app",
    valueId: "Download Aplikasi",
    valueEn: "Download App",
  },
  {
    key: "global_login",
    valueId: "Masuk",
    valueEn: "Login",
  },
  {
    key: "global_sign_in",
    valueId: "Masuk",
    valueEn: "Sign In",
  },
  {
    key: "global_sign_up",
    valueId: "Register",
    valueEn: "Sign Up",
  },
  {
    key: "global_completed",
    valueId: "Selesai",
    valueEn: "Completed",
  },
  {
    key: "global_back",
    valueId: "Kembali",
    valueEn: "Back",
  },
  {
    key: "global_experience",
    valueId: "Pengalaman",
    valueEn: "Experience",
  },
  {
    key: "global_expertise",
    valueId: "Keahlian",
    valueEn: "Expertise",
  },
  {
    key: "global_more",
    valueId: "Lainnya",
    valueEn: "More",
  },
  {
    key: "global_other",
    valueId: "Lainnya",
    valueEn: "Other",
  },
  {
    key: "global_skip",
    valueId: "Lewati",
    valueEn: "Skip",
  },
  {
    key: "global_continue",
    valueId: "Lanjut",
    valueEn: "Continue",
  },
  //Day
  {
    key: "day_monday",
    valueId: "Senin",
    valueEn: "Monday",
  },
  {
    key: "day_tuesday",
    valueId: "Selasa",
    valueEn: "Tuesday",
  },
  {
    key: "day_wednesday",
    valueId: "Rabu",
    valueEn: "Wednesday",
  },
  {
    key: "day_thursday",
    valueId: "Kamis",
    valueEn: "Thursday",
  },
  {
    key: "day_friday",
    valueId: "Jumat",
    valueEn: "Friday",
  },
  {
    key: "day_saturday",
    valueId: "Sabtu",
    valueEn: "Saturday",
  },
  {
    key: "day_sunday",
    valueId: "Minggu",
    valueEn: "Sunday",
  },
  //Category
  {
    key: "category_self",
    valueId: "👤️ Diri",
    valueEn: "👤️ Self",
  },
  {
    key: "category_partner",
    valueId: "💕 Pasangan",
    valueEn: "💕 Partner",
  },
  {
    key: "category_family",
    valueId: "👨‍👩‍👧‍👦 Keluarga",
    valueEn: "👨‍👩‍👧‍👦 Family",
  },
  {
    key: "category_friend",
    valueId: "👋🏻 Teman",
    valueEn: "👋🏻 Friends",
  },
  {
    key: "category_health",
    valueId: "🏋🏻‍♀️ Kesehatan",
    valueEn: "🏋🏻‍♀️ Health",
  },
  {
    key: "category_work",
    valueId: "💼 Pekerjaan",
    valueEn: "💼 Work",
  },
  {
    key: "category_education",
    valueId: "🏫 Pendidikan",
    valueEn: "🏫 Education",
  },
  {
    key: "category_finance",
    valueId: "💰 Keuangan",
    valueEn: "💰 Finance",
  },
  //Navbar
  {
    key: "navbar_get_matched",
    valueId: "Cocokkan Spesialis",
    valueEn: "Get Matched",
  },
  {
    key: "navbar_find_specialist",
    valueId: "Cari Spesialis",
    valueEn: "Find Specialist",
  },
  {
    key: "navbar_for_teams",
    valueId: "Maxi untuk Tim",
    valueEn: "For Teams",
  },
  {
    key: "navbar_faq",
    valueId: "FAQ",
    valueEn: "FAQ",
  },
  //Footer
  {
    key: "footer_chat_title",
    valueId: "Apakah kamu perlu informasi lebih lanjut?",
    valueEn: "Do you need more information?",
  },
  {
    key: "footer_chat_subtitle",
    valueId: "Jangan ragu untuk menghubungi kami!",
    valueEn: "Don’t hesitate to reach out to us!",
  },
  {
    key: "footer_chat_button",
    valueId: "Whatsapp Kami",
    valueEn: "Whatsapp Us",
  },
  {
    key: "footer_solutions_title",
    valueId: "Solusi",
    valueEn: "Solutions",
  },
  {
    key: "footer_solutions_menu_1",
    valueId: "Spesialis Kami",
    valueEn: "Our Specialist",
  },
  {
    key: "footer_solutions_menu_2",
    valueId: "Maxi untuk Tim",
    valueEn: "Maxi for Team",
  },
  {
    key: "footer_link_title",
    valueId: "Tautan",
    valueEn: "Link",
  },
  {
    key: "footer_link_menu_1",
    valueId: "Pernyataan Privasi",
    valueEn: "Privacy Statement",
  },
  {
    key: "footer_link_menu_2",
    valueId: "Ketentuan Layanan",
    valueEn: "Terms of Service",
  },
  {
    key: "footer_socmed_title",
    valueId: "Media Sosial",
    valueEn: "Social Media",
  },
  {
    key: "footer_download_title",
    valueId: "Unduh Aplikasi",
    valueEn: "Download App",
  },
  //Home Header
  {
    key: "home_header_title",
    valueId: "Langkah pertama kamu untuk hidup yang lebih bahagia",
    valueEn: "Your first step to a happier life",
  },
  {
    key: "home_header_description",
    valueId:
      "Terhubung dengan konselor, psikolog, dan pelatih terverifikasi dari mana saja di Indonesia",
    valueEn:
      "Connect with verified counselors, psychologists and coaches from anywhere in Indonesia",
  },
  {
    key: "home_header_get_matched",
    valueId: "Cocokkan Specialist",
    valueEn: "Get Matched",
  },
  {
    key: "home_header_browse_specialist",
    valueId: "Lihat Spesialis",
    valueEn: "Browse Specialist",
  },
  //Home Benefit
  {
    key: "home_plus_1_title",
    valueId: "Spesialis<br />Terverifikasi",
    valueEn: "Verified<br/>Specialists",
  },
  {
    key: "home_plus_1_subtitle",
    valueId: "Akses berbagai pilihan spesialis yang berkualitas",
    valueEn: "Access wide selection of qualified specialists",
  },
  {
    key: "home_plus_2_title",
    valueId: "Jaminan<br />Privasi",
    valueEn: "Privacy<br/>Guaranteed",
  },
  {
    key: "home_plus_2_subtitle",
    valueId: "Kode etik dan kerahasiaan yang ketat",
    valueEn: "Strict code of ethics and confidentiality",
  },
  {
    key: "home_plus_3_title",
    valueId: "Tidak<br />Judgemntal",
    valueEn: "Non<br/>Judgmental",
  },
  {
    key: "home_plus_3_subtitle",
    valueId: "Rasakan sesi konsultasi yang aman dan nyaman",
    valueEn: "Experience safe and comfortable consultation session",
  },
  {
    key: "home_plus_4_title",
    valueId: "Kebijakan<br />Refund",
    valueEn: "Refund<br/>Policy",
  },
  {
    key: "home_plus_4_subtitle",
    valueId: "Dapatkan refund untuk sesi konsultasi yang tidak memuaskan",
    valueEn: "Get a refund for unsatisfied consultation session",
  },
  //Home Specialist
  {
    key: "home_specialist_title",
    valueId: "Cari spesialis yang tepat untuk kamu",
    valueEn: "Find the right specialist for you",
  },
  {
    key: "home_specialist_button",
    valueId: "Lihat lebih banyak spesialis",
    valueEn: "View more specialists",
  },
  //Home Tipe
  {
    key: "home_type_title",
    valueId: "Konselor, Psikolog, dan Coach",
    valueEn: "Counselors, Psychologists, and Coaches",
  },
  {
    key: "home_type_subtitle",
    valueId:
      "Akses dukungan sesuai kebutuhanmu melalui konsultasi berbasis online",
    valueEn:
      "Match with our specialists and access on-demand support through voice call or video call.",
  },
  {
    key: "home_type_counselor_title",
    valueId: "Konselor",
    valueEn: "Counselors",
  },
  {
    key: "home_type_counselor_subtitle",
    valueId:
      "Konselor membantu kamu mengelola masalah pribadi seperti kecemasan dan stress, dan mengedukasi kamu tentang strategi-strategi yang dapat membantu untuk mengatasi masalah kamu.",
    valueEn:
      "Counselors help you manage personal issues such as anxiety and stress, and educate you on strategies that can help you overcome your problems.",
  },
  {
    key: "home_type_psycholog_title",
    valueId: "Psikolog",
    valueEn: "Psychologists",
  },
  {
    key: "home_type_psycholog_subtitle",
    valueId:
      "Psikolog membantu memeriksa dan mendiagnosis masalah mental, fisik, perilaku, dan kesehatan yang kamu miliki, serta memberikan rencana perawatan untuk kamu.",
    valueEn:
      "Psychologists help assess and diagnose your mental, physical or behavioral health issues and develop treatment plans for you.",
  },
  {
    key: "home_type_coach_title",
    valueId: "Coaches",
    valueEn: "Coaches",
  },
  {
    key: "home_type_coach_subtitle",
    valueId:
      "Coaches membantu untuk menemukan tujuan kamu, mengidentifikasi masalah dan membuat rencana yang dapat kamu lakukan untuk mencapai tujuan kamu.",
    valueEn:
      "Coaches help clarify your goals, identify obstacles and create actionable plans to help you reach your goals.",
  },
  //Home Testimony
  {
    key: "home_testimony_title",
    valueId: "Apa yang dikatakan pengguna kami",
    valueEn: "What our users say",
  },
  //Home Help
  {
    key: "home_help_title",
    valueId: "Dapatkan bantuan untuk masalah-masalah berikut",
    valueEn: "Get Help with Issues",
  },
  //Home Book
  {
    key: "home_book_title",
    valueId: "Cara memesan sesi",
    valueEn: "How to Book a Session",
  },
  {
    key: "home_book_1_title",
    valueId: "Pilih Spesialis",
    valueEn: "Select Specialist",
  },
  {
    key: "home_book_1_subtitle",
    valueId: "Pilih spesialis yang sesuai dengan kebutuhan kamu",
    valueEn: "Select a specialist that fits your needs",
  },
  {
    key: "home_book_2_title",
    valueId: "Pilih Jadwal",
    valueEn: "Select Schedule",
  },
  {
    key: "home_book_2_subtitle",
    valueId: "Pilih waktu dan tanggal yang kamu inginkan",
    valueEn: "Select your preferred date and time",
  },
  {
    key: "home_book_3_title",
    valueId: "Lakukan Pembayaran",
    valueEn: "Make Payment",
  },
  {
    key: "home_book_3_subtitle",
    valueId: "Pilih metode pembayaran",
    valueEn: "Choose your payment method",
  },
  {
    key: "home_book_4_title",
    valueId: "Panggilan Google Meet",
    valueEn: "Google Meet Call",
  },
  {
    key: "home_book_4_subtitle",
    valueId: "Bergabung dengan Google Meet untuk memulai sesi kamu",
    valueEn: "Start your session by joining google meet call",
  },
  //Home Download
  {
    key: "home_download_title",
    valueId: "Asisten kesehatan mental pribadi kamu",
    valueEn: "Your personal mental health assistant",
  },
  {
    key: "home_download_subtitle",
    valueId: "Mulai perjalanan kebahagiaan kamu hari ini",
    valueEn: "Start your wellbeing journey today",
  },
  //Matched
  {
    key: "matched_category_title",
    valueId: "Saya butuh bantuan dalam aspek ini",
    valueEn: "I need help on this aspect",
  },
  {
    key: "matched_category_subtitle",
    valueId: "Pilih satu topik yang ingin kamu diskusikan dengan spesialismu",
    valueEn: "Choose one topic that you want to discuss with your specialist",
  },
  {
    key: "matched_expertise_title",
    valueId: "Saya perlu bantuan untuk hal ini",
    valueEn: "I have the following issue",
  },
  {
    key: "matched_expertise_subtitle",
    valueId: "Pilih masalah yang kamu alami",
    valueEn: "Choose one or more issues that you are experiencing",
  },
  {
    key: "matched_experience_title",
    valueId: "Saya pilih spesialis ini",
    valueEn: "I prefer this specialist",
  },
  {
    key: "matched_experience_subtitle",
    valueId: "Pilih spesialis berdasarkan pengalamannya",
    valueEn:
      "Choose one preferred specialist option according to their experience",
  },
  {
    key: "matched_experience_opt_1_title",
    valueId: "Spesialis Junior",
    valueEn: "Junior Specialist",
  },
  {
    key: "matched_experience_opt_1_subtitle",
    valueId: "< 5 tahun pengalaman",
    valueEn: "< 5 years experience",
  },
  {
    key: "matched_experience_opt_2_title",
    valueId: "Spesialis Senior",
    valueEn: "Senior Specialist",
  },
  {
    key: "matched_experience_opt_2_subtitle",
    valueId: "> 5 tahun pengalaman",
    valueEn: "> 5 years experience",
  },
  {
    key: "matched_day_title",
    valueId: "Saya pilih hari ini",
    valueEn: "I prefer the following days",
  },
  {
    key: "matched_day_subtitle",
    valueId: "Pilih hari sesuai dengan ketersediaan jadwalmu",
    valueEn: "Choose one or more preferred consultation schedule days",
  },
  {
    key: "matched_language_title",
    valueId: "Saya pilih bahasa ini",
    valueEn: "I prefer this language",
  },
  {
    key: "matched_language_subtitle",
    valueId: "Pilih bahasa yang biasa kamu pakai",
    valueEn: "Chooose one or more specialist languages that you prefer",
  },
  {
    key: "matched_language_opt_1",
    valueId: "Bahasa Indonesia",
    valueEn: "Indonesian",
  },
  {
    key: "matched_language_opt_2",
    valueId: "Bahasa Inggris",
    valueEn: "English",
  },
  {
    key: "matched_language_opt_3",
    valueId: "Keduanya",
    valueEn: "Both",
  },
  {
    key: "matched_type_title",
    valueId: "Saya lagi mencari spesialis ini",
    valueEn: "I am looking for",
  },
  {
    key: "matched_type_subtitle",
    valueId: "Pilih spesialis sesuai kebutuhanmu",
    valueEn:
      "Choose one option that represents the specialist that will be a great fit for you",
  },
  {
    key: "matched_button",
    valueId: "Cocokkan Spesialis",
    valueEn: "Get Matched",
  },
  //Teams_banner
  {
    key: "teams_banner_title",
    valueId: "Dukungan kesejahteraan untuk tim modern",
    valueEn: "Wellbeing support for modern team",
  },
  {
    key: "teams_banner_subtitle",
    valueId:
      "Ubah cara Anda membangun tempat kerja yang interaktif dan produktif",
    valueEn: "Transform the way you build an engaged and productive workplace",
  },
  {
    key: "teams_banner_book",
    valueId: "Pesan Demo",
    valueEn: "Book Demo",
  },
  {
    key: "teams_banner_browse",
    valueId: "Lihat Spesialis",
    valueEn: "Browse Specialist",
  },
  {
    key: "teams_partners_title",
    valueId: "Kami dipercaya oleh banyak tim",
    valueEn: "We are trusted by many teams",
  },
  {
    key: "teams_benefit_1_title",
    valueId: "Seminar",
    valueEn: "Seminar",
  },
  {
    key: "teams_benefit_1_subtitle",
    valueId:
      "Kami membantu Anda meningkatkan program kesejahteraan perusahaan Anda dengan webinar menarik yang disesuaikan dengan kebutuhan Anda, dengan topik mulai dari mengelola stres hingga mengatasi sindrom penipu",
    valueEn:
      "We help you boost your company’s wellbeing program with an engaging webinar tailored to your needs, with topics from managing stress to overcoming impostor syndrome",
  },
  {
    key: "teams_benefit_2_title",
    valueId: "Penilaian",
    valueEn: "Assessment",
  },
  {
    key: "teams_benefit_2_subtitle",
    valueId:
      "Kami menawarkan penilaian online dan tatap muka untuk membantu Anda mendapatkan gambaran kesejahteraan perusahaan Anda, seperti mengidentifikasi tanda-tanda tekanan di tempat kerja Anda",
    valueEn:
      "We offer online and in-person assessment to help you get a wellbeing pulse of your company, such as identifying signs of distress in your workplace",
  },
  {
    key: "teams_benefit_3_title",
    valueId: "Konseling Pribadi",
    valueEn: "Private Counseling",
  },
  {
    key: "teams_benefit_3_subtitle",
    valueId:
      "Kami bermitra dengan spesialis kesejahteraan mental seperti konselor, psikolog, dan pelatih untuk memberikan perawatan yang dipersonalisasi untuk perusahaan Anda.",
    valueEn:
      "We partner with mental wellbeing specialists such as counselors, psychologists and coaches to provide a personalised care for your company.",
  },
  {
    key: "teams_why_title",
    valueId: "Mengapa Perusahaan memilih kami?",
    valueEn: "Why Employers choose us?",
  },
  {
    key: "teams_why_subtitle",
    valueId:
      "Kami memahami bahwa setiap organisasi menghadapi tantangan yang unik. Pengusaha memilih kami karena solusi kami dirancang agar fleksibel terhadap kebutuhan pribadi mereka.",
    valueEn:
      "We understand that every organisation faces unique challenges. Employers choose us because our solution is designed to be flexible to their personalised needs.",
  },
  {
    key: "teams_why_button",
    valueId: "Pesan Demo",
    valueEn: "Book Demo",
  },
  {
    key: "teams_testimony_title",
    valueId: "Testimoni pemberi kerja",
    valueEn: "Employer testimonials",
  },
  {
    key: "teams_featured_title",
    valueId: "Seperti yang Ditampilkan Dalam",
    valueEn: "As Featured In",
  },
  {
    key: "teams_book_title",
    valueId: "Seperti yang Ditampilkan Dalam",
    valueEn: "Book a Demo",
  },
  {
    key: "teams_book_email_title",
    valueId: "ALAMAT EMAIL",
    valueEn: "EMAIL ADDRESS",
  },
  {
    key: "teams_book_email_hint",
    valueId: "Masukkan Email",
    valueEn: "Input Email",
  },
  {
    key: "teams_book_phone_title",
    valueId: "NO HP",
    valueEn: "MOBILE NUMBER",
  },
  {
    key: "teams_book_phone_hint",
    valueId: "Masukkan No HP",
    valueEn: "Input Mobile Number",
  },
  {
    key: "teams_book_company_title",
    valueId: "NAMA PERUSAHAAN (JIKA BERLAKU)",
    valueEn: "COMPANY NAME (IF APPLICABLE)",
  },
  {
    key: "teams_book_company_hint",
    valueId: "Masukkan Nama Perusahaan",
    valueEn: "Input Company Name",
  },
  {
    key: "teams_book_employee_title",
    valueId: "JUMLAH KARYAWAN (JIKA BERLAKU)",
    valueEn: "NUMBER OF EMPLOYEES (IF APPLICABLE)",
  },
  {
    key: "teams_book_employee_hint",
    valueId: "Pilih Jumlah Karyawan",
    valueEn: "Choose Number of Employees",
  },
  {
    key: "teams_book_hear_title",
    valueId: "Bagaimana Anda mendengar tentang kami?",
    valueEn: "How did you hear about us?",
  },
  {
    key: "teams_book_hear_subtitle",
    valueId: "Centang satu atau lebih",
    valueEn: "Tick one or more",
  },
  {
    key: "teams_book_button",
    valueId: "Pesan Demo",
    valueEn: "Book Demo",
  },
  {
    key: "faq_1_title",
    valueId: "Akun",
    valueEn: "Accounts",
  },
  {
    key: "faq_1_q",
    valueId:
      "Dapatkah saya menggunakan akun Maxi saya untuk masuk ke situs web atau aplikasi Maxi?",
    valueEn:
      "Can I use my Maxi account to sign in to website or Maxi Mobile App?",
  },
  {
    key: "faq_1_a_1",
    valueId: "Ya. ",
    valueEn: "Yes. ",
  },
  {
    key: "faq_1_a_2",
    valueId:
      "jika Anda sudah mendaftar dan memiliki akun Maxi, Anda dapat Masuk ke situs web dan Aplikasi Maxi dengan menggunakan email dan kata sandi yang Anda daftarkan sebelumnya.",
    valueEn:
      "If you have already signed up and have a Maxi account, you can Log In to website and Maxi Mobile App by using the email and password that you have registered.",
  },
  {
    key: "faq_2_title",
    valueId: "Pesan jadwal",
    valueEn: "Book a schedule",
  },
  {
    key: "faq_2_q",
    valueId: "Bagaimana cara saya memesan sesi?",
    valueEn: "How do I book a session?",
  },
  {
    key: "faq_2_a_a",
    valueId: "Melalui Website:",
    valueEn: "Via Website:",
  },
  {
    key: "faq_2_a_a_1_1",
    valueId: "Tekan menu ",
    valueEn: "Go to ",
  },
  {
    key: "faq_2_a_a_1_2",
    valueId: "Cari Spesialis ",
    valueEn: "Find Specialist ",
  },
  {
    key: "faq_2_a_a_1_3",
    valueId: "",
    valueEn: "Menu",
  },
  {
    key: "faq_2_a_a_2_1",
    valueId:
      "Pilih spesialis yang paling cocok dengan kebutuhan Anda dengan mengtekan ",
    valueEn: "Choose a specialist that best fits your needs by clicking ",
  },
  {
    key: "faq_2_a_a_2_2",
    valueId: "Lihat Profil ",
    valueEn: "View Profile ",
  },
  {
    key: "faq_2_a_a_2_3",
    valueId: "Anda juga dapat langsung memesan jadwal dengan mengtekan ",
    valueEn: "You can also book a schedule directly by clicking ",
  },
  {
    key: "faq_2_a_a_2_4",
    valueId: "Pesan jadwal.",
    valueEn: "Book a schedule.",
  },
  {
    key: "faq_2_a_a_3_1",
    valueId: "Di halaman profil spesialis, tekan ",
    valueEn: "In specialist profile page, click ",
  },
  {
    key: "faq_2_a_a_3_2",
    valueId: "Pesan jadwal sekarang",
    valueEn: "Book a schedule now",
  },
  {
    key: "faq_2_a_a_4_1",
    valueId:
      "Pilih metode konsultasi yang diinginkan, tanggal, dan waktu lalu tekan ",
    valueEn: "Select preferred consultation method, date and time and click ",
  },
  {
    key: "faq_2_a_a_4_2",
    valueId: "Lanjut",
    valueEn: "Next",
  },
  {
    key: "faq_2_a_a_5_1",
    valueId: "Jika Anda sudah memiliki akun Maxi, Anda dapat ",
    valueEn: "If you already have a Maxi account, you can ",
  },
  {
    key: "faq_2_a_a_5_2",
    valueId: "Masuk ",
    valueEn: "Login ",
  },
  {
    key: "faq_2_a_a_5_3",
    valueId:
      "dengan email dan kata sandi Anda. Jika belum memiliki akun, Anda dapat ",
    valueEn:
      "with your email and password. If you do not have an account, you can ",
  },
  {
    key: "faq_2_a_a_5_4",
    valueId: "Daftar ",
    valueEn: "Sign Up ",
  },
  {
    key: "faq_2_a_a_5_5",
    valueId: "dengan memasukkan email dan kata sandi.",
    valueEn: "by entering your email and password.",
  },
  {
    key: "faq_2_a_a_6_1",
    valueId: "Pilih ",
    valueEn: "Select a ",
  },
  {
    key: "faq_2_a_a_6_2",
    valueId: "metode pembayaran. ",
    valueEn: "payment method. ",
  },
  {
    key: "faq_2_a_a_6_3",
    valueId:
      "Anda juga dapat memasukkan kode promo dan Poin Maxi dalam pembayaran Anda.",
    valueEn:
      "You can also insert a promo code and Maxi Points in your payment.",
  },
  {
    key: "faq_2_a_a_7_1",
    valueId: "Tekan ",
    valueEn: "Click ",
  },
  {
    key: "faq_2_a_a_7_2",
    valueId: "Bayar ",
    valueEn: "Pay ",
  },
  {
    key: "faq_2_a_a_7_3",
    valueId: "dan selesaikan pembayaran Anda.",
    valueEn: "and complete your payment.",
  },
  {
    key: "faq_2_a_b",
    valueId: "Melalui Aplikasi Maxi Mobile:",
    valueEn: "Via Maxi Mobile App:",
  },
  {
    key: "faq_2_a_b_1_1",
    valueId: "Tekan menu ",
    valueEn: "Go to ",
  },
  {
    key: "faq_2_a_b_1_2",
    valueId: "Konsultasi Online",
    valueEn: "Online Consultation ",
  },
  {
    key: "faq_2_a_b_1_3",
    valueId: "",
    valueEn: "menu",
  },
  {
    key: "faq_2_a_b_2_1",
    valueId: "Pilih spesialis yang paling cocok dengan kebutuhan Anda",
    valueEn: "Choose a specialist that best fits your needs",
  },
  {
    key: "faq_2_a_b_3_1",
    valueId: "Di halaman profil spesialis, tekan ",
    valueEn: "In specialist profile page, click ",
  },
  {
    key: "faq_2_a_b_3_2",
    valueId: "Lihat Ketersediaan ",
    valueEn: "See Availability ",
  },
  {
    key: "faq_2_a_b_3_3",
    valueId: "untuk melihat jadwal yang tersedia",
    valueEn: "to see available schedule",
  },
  {
    key: "faq_2_a_b_4_1",
    valueId:
      "Pilih metode konsultasi yang diinginkan, tanggal, dan waktu lalu tekan ",
    valueEn: "Select preferred consultation method, date and time and click ",
  },
  {
    key: "faq_2_a_b_4_2",
    valueId: "Lanjut",
    valueEn: "Next",
  },
  {
    key: "faq_2_a_b_5_1",
    valueId: "Pilih ",
    valueEn: "Select a ",
  },
  {
    key: "faq_2_a_b_5_2",
    valueId: "metode pembayaran. ",
    valueEn: "payment method. ",
  },
  {
    key: "faq_2_a_b_5_3",
    valueId:
      "Anda juga dapat memasukkan kode promo dan Poin Maxi dalam pembayaran Anda.",
    valueEn:
      "You can also insert a promo code and Maxi Points in your payment.",
  },
  {
    key: "faq_2_a_b_6_1",
    valueId: "Tekan ",
    valueEn: "Click ",
  },
  {
    key: "faq_2_a_b_6_2",
    valueId: "Bayar ",
    valueEn: "Pay ",
  },
  {
    key: "faq_2_a_b_6_3",
    valueId: "dan selesaikan pembayaran Anda.",
    valueEn: "and complete your payment.",
  },
  {
    key: "faq_3_title",
    valueId: "Pembayaran",
    valueEn: "Payment",
  },
  {
    key: "faq_3_q_1",
    valueId: "Metode pembayaran apa saja yang didukung?",
    valueEn: "What payment methods are supported?",
  },
  {
    key: "faq_3_a_1",
    valueId:
      "Anda dapat melakukan pembayaran melalui Transfer Bank seperti BCA, BRI, BNI, dan Mandiri. Kami juga menyediakan pembayaran melalui E-wallet seperti GoPay, OVO, ShopeePay, dan Dana.",
    valueEn:
      "You can make your payment via Bank Transfer such as BCA, BRI, BNI, and Mandiri. We also provide payment via E-wallet such as GoPay, OVO, ShopeePay, and Dana.",
  },
  {
    key: "faq_3_q_2",
    valueId: "Apa yang terjadi jika saya tidak menyelesaikan pembayaran saya?",
    valueEn: "What happens if I do not complete my payment?",
  },
  {
    key: "faq_3_a_2",
    valueId:
      "Jika Anda tidak menyelesaikan pembayaran dalam batas waktu satu jam, pemesanan Anda akan secara otomatis dibatalkan.",
    valueEn:
      "If you do not complete your payment within the one hour time limit, your booking will automatically be cancelled. ",
  },
  {
    key: "faq_4_title",
    valueId: "Sesi",
    valueEn: "Sessions",
  },
  {
    key: "faq_4_q_1",
    valueId: "Bagaimana cara saya bergabung ke sesi saya?",
    valueEn: "How do I join my session?",
  },
  {
    key: "faq_4_q_1_a",
    valueId: "Melalui website:",
    valueEn: "Via website:",
  },
  {
    key: "faq_4_q_1_a_1_1",
    valueId: "Masuk ",
    valueEn: "Log In ",
  },
  {
    key: "faq_4_q_1_a_1_2",
    valueId:
      "ke akun Anda di www.maxiclinic.co. Anda dapat melihat sesi Anda di ",
    valueEn:
      "to your account on www.maxiclinic.co. You can view your session on ",
  },
  {
    key: "faq_4_q_1_a_1_3",
    valueId: "Home ",
    valueEn: "Home ",
  },
  {
    key: "faq_4_q_1_a_1_4",
    valueId: "atau tekan menu ",
    valueEn: "or you can go to ",
  },
  {
    key: "faq_4_q_1_a_1_5",
    valueId: "Sesi",
    valueEn: "Sessions ",
  },
  {
    key: "faq_4_q_1_a_1_6",
    valueId: "",
    valueEn: "menu.",
  },
  {
    key: "faq_4_q_1_a_2",
    valueId: "Pilih tanggal konsultasi Anda di kalender.",
    valueEn: "Select your consultation date on the calendar.",
  },
  {
    key: "faq_4_q_1_a_3_1",
    valueId: "Pada halaman Info Sesi, tekan ",
    valueEn: "On Session’s Info page, click ",
  },
  {
    key: "faq_4_q_1_a_3_2",
    valueId: "Bergabung dengan Google Meet ",
    valueEn: "Join Google Meet ",
  },
  {
    key: "faq_4_q_1_a_3_3",
    valueId: "dan Anda akan langsung diarahkan ke ruang Google Meet.",
    valueEn: "and you will be directly redirected to Google Meet room.",
  },
  {
    key: "faq_4_q_1_b",
    valueId: "Melalui Aplikasi Maxi Mobile:",
    valueEn: "Via Maxi Mobile App:",
  },
  {
    key: "faq_4_q_1_b_1",
    valueId: "Masuk ke akun Anda menggunakan email dan kata sandi Anda.",
    valueEn: "Log In to your account using your email and password.",
  },
  {
    key: "faq_4_q_1_b_2_1",
    valueId: "Tekan menu ",
    valueEn: "Go to ",
  },
  {
    key: "faq_4_q_1_b_2_2",
    valueId: "Aktivitas.",
    valueEn: "Activity ",
  },
  {
    key: "faq_4_q_1_b_2_3",
    valueId: "",
    valueEn: "menu.",
  },
  {
    key: "faq_4_q_1_b_3_1",
    valueId:
      "Pilih tanggal konsultasi Anda di kalender, atau Anda dapat tekan ",
    valueEn: "Select your consultation date on the calendar, or you can click ",
  },
  {
    key: "faq_4_q_1_b_3_2",
    valueId: "Riwayat, ",
    valueEn: "History, ",
  },
  {
    key: "faq_4_q_1_b_3_3",
    valueId: "tekan filter ",
    valueEn: "click ",
  },
  {
    key: "faq_4_q_1_b_3_4",
    valueId: "Semua Jenis, ",
    valueEn: "All Types ",
  },
  {
    key: "faq_4_q_1_b_3_5",
    valueId: "dan tekan ",
    valueEn: "filter and click ",
  },
  {
    key: "faq_4_q_1_b_3_6",
    valueId: "Konsultasi ",
    valueEn: "Consultation ",
  },
  {
    key: "faq_4_q_1_b_3_7",
    valueId: "untuk menampilkan sesi konsultasi Anda.",
    valueEn: "to show your consultation sessions.",
  },
  {
    key: "faq_4_q_1_b_4_1",
    valueId: "Tekan sesi Anda. Pada halaman Sesi Terpesan, tekan ",
    valueEn: "Click your session. On Booked Session page, click ",
  },
  {
    key: "faq_4_q_1_b_4_2",
    valueId: "Bergabung dengan Google Meet ",
    valueEn: "Join Google Meet ",
  },
  {
    key: "faq_4_q_1_b_4_3",
    valueId: "dan Anda akan langsung diarahkan ke ruang Google Meet.",
    valueEn: "and you will be directly redirected to Google Meet room.",
  },
  {
    key: "faq_4_i_1",
    valueId: "Anda juga bisa bergabung ke sesi Anda dengan menggunakan ",
    valueEn: "You can also join your session using the ",
  },
  {
    key: "faq_4_i_2",
    valueId: "Tautan Google Meet yang ada dalam invoice pemesanan ",
    valueEn: "Google Meet Link in booking invoice ",
  },
  {
    key: "faq_4_i_3",
    valueId: "yang kami kirim melalui email yang telah Anda daftarkan.",
    valueEn: "that we sent via the email you provided.",
  },
  {
    key: "faq_4_q_2",
    valueId:
      "Apa yang harus saya lakukan setelah saya telah memesan sesi saya?",
    valueEn: "What should I do after I have booked my session?",
  },
  {
    key: "faq_4_q_2_a",
    valueId:
      "Setelah pembayaran Anda selesai, Anda dapat mengisi formulir Pra-konseling dan menambahkan sesi Anda ke Kalender Google.",
    valueEn:
      "After your payment is completed, you can fill in the Pre-counseling form and add your session to Google Calendar. ",
  },
  {
    key: "faq_5_title",
    valueId: "Pembatalan, Penjadwalan Ulang, dan Refund sesi",
    valueEn: "Cancel, Reschedule and Refund sessions",
  },
  {
    key: "faq_5_q_1",
    valueId: "Bisakah saya mengubah jadwal sesi saya?",
    valueEn: "Can I reschedule my session?",
  },
  {
    key: "faq_5_q_1_d",
    valueId:
      "Anda hanya dapat mengubah jadwal sesi Anda setidaknya 6 jam sebelum sesi dimulai. Anda tidak dapat mengubah jadwal jika kurang dari 6 jam sebelumnya.",
    valueEn:
      "You can only reschedule your session at least 6 hours before your session starts. You will not be able to reschedule later than 6 hours.",
  },
  {
    key: "faq_5_q_1_a",
    valueId: "Melalui website:",
    valueEn: "Via website:",
  },
  {
    key: "faq_5_q_1_a_1_1",
    valueId: "Masuk ",
    valueEn: "Login ",
  },
  {
    key: "faq_5_q_1_a_1_2",
    valueId:
      "ke akun Anda di www.maxiclinic.co. Anda bisa melihat sesi Anda di ",
    valueEn:
      "to your account on www.maxiclinic.co. You can view your session on ",
  },
  {
    key: "faq_5_q_1_a_1_3",
    valueId: "Home ",
    valueEn: "Home ",
  },
  {
    key: "faq_5_q_1_a_1_4",
    valueId: "atau pergi ke menu ",
    valueEn: "or you can go to ",
  },
  {
    key: "faq_5_q_1_a_1_5",
    valueId: "Sesi.",
    valueEn: "Sessions ",
  },
  {
    key: "faq_5_q_1_a_1_6",
    valueId: "",
    valueEn: "menu.",
  },
  {
    key: "faq_5_q_1_a_2",
    valueId: "Pilih tanggal konsultasi Anda di kalender.",
    valueEn: "Select your consultation date on the calendar.",
  },
  {
    key: "faq_5_q_1_a_3_1",
    valueId: "Pada halaman Info Sesi, tekan ",
    valueEn: "On Session’s Info page, click ",
  },
  {
    key: "faq_5_q_1_a_3_2",
    valueId: "Ubah Jadwal.",
    valueEn: "Reschedule.",
  },
  {
    key: "faq_5_q_1_a_4",
    valueId: "Pilih tanggal dan waktu baru untuk sesi Anda.",
    valueEn: "Select new date and time for your session.",
  },
  {
    key: "faq_5_q_1_a_5_1",
    valueId: "Tekan ",
    valueEn: "Click ",
  },
  {
    key: "faq_5_q_1_a_5_2",
    valueId: "Ubah Jadwal.",
    valueEn: "Reschedule.",
  },
  {
    key: "faq_5_q_1_b",
    valueId: "Melalui Aplikasi Maxi Mobile:",
    valueEn: "Via Maxi Mobile App:",
  },
  {
    key: "faq_5_q_1_b_1",
    valueId: "Masuk ke akun Anda menggunakan email dan sandi Anda.",
    valueEn: "Log In to your account using your email and password.",
  },
  {
    key: "faq_5_q_1_b_2_1",
    valueId: "Tekan menu ",
    valueEn: "Go to ",
  },
  {
    key: "faq_5_q_1_b_2_2",
    valueId: "Aktivitas.",
    valueEn: "Activity ",
  },
  {
    key: "faq_5_q_1_b_2_3",
    valueId: "",
    valueEn: "menu.",
  },
  {
    key: "faq_5_q_1_b_3_1",
    valueId:
      "Pilih tanggal konsultasi Anda di kalender, atau Anda dapat tekan ",
    valueEn: "Select your consultation date on the calendar, or you can click ",
  },
  {
    key: "faq_5_q_1_b_3_2",
    valueId: "Riwayat, ",
    valueEn: "History, ",
  },
  {
    key: "faq_5_q_1_b_3_3",
    valueId: "tekan filter ",
    valueEn: "click ",
  },
  {
    key: "faq_5_q_1_b_3_4",
    valueId: "Semua Jenis, ",
    valueEn: "All Types ",
  },
  {
    key: "faq_5_q_1_b_3_5",
    valueId: "dan tekan ",
    valueEn: "filter and click ",
  },
  {
    key: "faq_5_q_1_b_3_6",
    valueId: "Konsultasi ",
    valueEn: "Consultation ",
  },
  {
    key: "faq_5_q_1_b_3_7",
    valueId: "untuk menampilkan sesi konsultasi Anda.",
    valueEn: "to show your consultation sessions.",
  },
  {
    key: "faq_5_q_1_b_4_1",
    valueId: "Tekan sesi Anda. Pada halaman Sesi Terpesan, tekan ",
    valueEn: "Click your session. On Booked Session page, click ",
  },
  {
    key: "faq_5_q_1_b_4_2",
    valueId: "Ubah Jadwal.",
    valueEn: "Reschedule.",
  },
  {
    key: "faq_5_q_1_b_5",
    valueId: "Pilih tanggal dan waktu baru untuk sesi Anda.",
    valueEn: "Select new date and time for your session.",
  },
  {
    key: "faq_5_q_1_b_6_1",
    valueId: "Tekan ",
    valueEn: "Click ",
  },
  {
    key: "faq_5_q_1_b_6_2",
    valueId: "Ubah Jadwal.",
    valueEn: "Reschedule.",
  },
  {
    key: "faq_5_q_2",
    valueId: "Bisakah saya membatalkan sesi saya?",
    valueEn: "Can I cancel my session?",
  },
  {
    key: "faq_5_q_2_d",
    valueId:
      "Anda hanya dapat membatalkan sesi Anda paling lambat 6 jam sebelum sesi dimulai. Anda tidak dapat membatalkan setelah 6 jam.",
    valueEn:
      "You can only cancel your session at least 6 hours before your session starts. You will not be able to cancel later than 6 hours.",
  },
  {
    key: "faq_5_q_2_a",
    valueId: "Melalui Website:",
    valueEn: "Via Website:",
  },
  {
    key: "faq_5_q_2_a_1_1",
    valueId: "Masuk ",
    valueEn: "Login ",
  },
  {
    key: "faq_5_q_2_a_1_2",
    valueId:
      "ke akun Anda di www.maxiclinic.co. Anda bisa melihat sesi Anda di ",
    valueEn:
      "to your account on www.maxiclinic.co. You can view your session on ",
  },
  {
    key: "faq_5_q_2_a_1_3",
    valueId: "Home ",
    valueEn: "Home ",
  },
  {
    key: "faq_5_q_2_a_1_4",
    valueId: "atau pergi ke menu ",
    valueEn: "or you can go to ",
  },
  {
    key: "faq_5_q_2_a_1_5",
    valueId: "Sesi.",
    valueEn: "Sessions ",
  },
  {
    key: "faq_5_q_2_a_1_6",
    valueId: "",
    valueEn: "menu.",
  },
  {
    key: "faq_5_q_2_a_2",
    valueId: "Pilih tanggal konsultasi Anda di kalender.",
    valueEn: "Select your consultation date on the calendar.",
  },
  {
    key: "faq_5_q_2_a_3_1",
    valueId: "Pada halaman Info Sesi, tekan ",
    valueEn: "On Session’s Info page, click ",
  },
  {
    key: "faq_5_q_2_a_3_2",
    valueId: "Batalkan.",
    valueEn: "Reschedule.",
  },
  {
    key: "faq_5_q_2_a_4",
    valueId: "Pilih alasan pembatalan Anda dan masukkan email Anda.",
    valueEn: "Select new date and time for your session.",
  },
  {
    key: "faq_5_q_2_a_5_1",
    valueId: "Tekan ",
    valueEn: "Click ",
  },
  {
    key: "faq_5_q_2_a_5_2",
    valueId: "Kirim.",
    valueEn: "Submit.",
  },
  {
    key: "faq_5_q_2_a_5_2",
    valueId: "Kirim.",
    valueEn: "Submit.",
  },
  {
    key: "faq_5_q_2_b",
    valueId: "Melalui Aplikasi Maxi Mobile:",
    valueEn: "Via Maxi Mobile App:",
  },
  {
    key: "faq_5_q_2_b_1",
    valueId: "Masuk ke akun Anda menggunakan email dan sandi Anda.",
    valueEn: "Log In to your account using your email and password.",
  },
  {
    key: "faq_5_q_2_b_2_1",
    valueId: "Tekan menu ",
    valueEn: "Go to ",
  },
  {
    key: "faq_5_q_2_b_2_2",
    valueId: "Aktivitas.",
    valueEn: "Activity ",
  },
  {
    key: "faq_5_q_2_b_2_3",
    valueId: "",
    valueEn: "menu.",
  },
  {
    key: "faq_5_q_2_b_3_1",
    valueId:
      "Pilih tanggal konsultasi Anda di kalender, atau Anda dapat tekan ",
    valueEn: "Select your consultation date on the calendar, or you can click ",
  },
  {
    key: "faq_5_q_2_b_3_2",
    valueId: "Riwayat, ",
    valueEn: "History, ",
  },
  {
    key: "faq_5_q_2_b_3_3",
    valueId: "tekan filter ",
    valueEn: "click ",
  },
  {
    key: "faq_5_q_2_b_3_4",
    valueId: "Semua Jenis, ",
    valueEn: "All Types ",
  },
  {
    key: "faq_5_q_2_b_3_5",
    valueId: "dan tekan ",
    valueEn: "filter and click ",
  },
  {
    key: "faq_5_q_2_b_3_6",
    valueId: "Konsultasi ",
    valueEn: "Consultation ",
  },
  {
    key: "faq_5_q_2_b_3_7",
    valueId: "untuk menampilkan sesi konsultasi Anda.",
    valueEn: "to show your consultation sessions.",
  },
  {
    key: "faq_5_q_2_b_4_1",
    valueId: "Tekan sesi Anda. Pada halaman Sesi Terpesan, tekan ",
    valueEn: "Click your session. On Booked Session page, click ",
  },
  {
    key: "faq_5_q_2_b_4_2",
    valueId: "Batalkan.",
    valueEn: "Cancel.",
  },
  {
    key: "faq_5_q_2_b_5",
    valueId: "Pilih alasan pembatalan dan masukkan email Anda.",
    valueEn: "Select your cancellation reason and insert your email.",
  },
  {
    key: "faq_5_q_2_b_6_1",
    valueId: "Tekan ",
    valueEn: "Click ",
  },
  {
    key: "faq_5_q_2_b_6_2",
    valueId: "Kirim.",
    valueEn: "Submit.",
  },
  {
    key: "faq_5_q_2_i",
    valueId:
      "Customer service kami akan menghubungi Anda mengenai refund pembatalan melalui alamat email yang Anda berikan.",
    valueEn:
      "Our customer service will reach out to you about your cancellation refund via the email address you provided.",
  },
  {
    key: "faq_5_q_3",
    valueId: "Bagaimana jika spesialis saya mengubah jadwal sesi?",
    valueEn: "What if my specialist reschedules the session?",
  },
  {
    key: "faq_5_q_3_a",
    valueId:
      "Anda dapat memilih untuk mengubah jadwal atau membatalkan sesi melalui situs web atau Aplikasi Maxi. Jika Anda memilih untuk mengubah jadwal, Anda dapat menentukan tanggal dan waktu baru. Tetapi jika Anda memilih untuk membatalkan, Anda dapat mengirimkan permintaan pembatalan dan kami akan menghubungi Anda mengenai refund pembatalan Anda.",
    valueEn:
      "You can choose to reschedule or cancel your session via website or Maxi Mobile App. If you choose to reschedule, you can set a new date and time. But if you choose to cancel, you can submit a cancellation request and we will reach out to you about your cancellation refund.",
  },
  {
    key: "faq_5_q_4",
    valueId:
      "Saya tidak puas dengan spesialis saya. Bisakah saya mendapatkan refund?",
    valueEn: "I was not satisfied with my specialist. Can I get a refund?",
  },
  {
    key: "faq_5_q_4_d_1",
    valueId: "Ya, Anda bisa. ",
    valueEn: "Yes, you can. ",
  },
  {
    key: "faq_5_q_4_d_2",
    valueId:
      "Maxi selalu berusaha untuk memberikan pengalaman konsultasi terbaik bagi Anda.",
    valueEn:
      "Maxi always ensures to provide you with the best consultation experience.",
  },
  {
    key: "faq_5_q_4_a",
    valueId: "Melalui website:",
    valueEn: "Via website:",
  },
  {
    key: "faq_5_q_4_a_1_1",
    valueId: "Masuk ",
    valueEn: "Login ",
  },
  {
    key: "faq_5_q_4_a_1_2",
    valueId:
      "ke akun Anda di www.maxiclinic.co. Anda bisa melihat sesi Anda di ",
    valueEn:
      "to your account on www.maxiclinic.co. You can view your session on ",
  },
  {
    key: "faq_5_q_4_a_1_3",
    valueId: "Home ",
    valueEn: "Home ",
  },
  {
    key: "faq_5_q_4_a_1_4",
    valueId: "atau pergi ke menu ",
    valueEn: "or you can go to ",
  },
  {
    key: "faq_5_q_4_a_1_5",
    valueId: "Sesi.",
    valueEn: "Sessions ",
  },
  {
    key: "faq_5_q_4_a_1_6",
    valueId: "",
    valueEn: "menu.",
  },
  {
    key: "faq_5_q_4_a_2",
    valueId: "Pilih tanggal konsultasi Anda di kalender.",
    valueEn: "Select your consultation date on the calendar.",
  },
  {
    key: "faq_5_q_4_a_3_1",
    valueId: "Pada halaman Info Sesi, tekan ",
    valueEn: "On Session’s Info page, click ",
  },
  {
    key: "faq_5_q_4_a_3_2",
    valueId: "tanda jempol ke bawah ",
    valueEn: "thumb down ",
  },
  {
    key: "faq_5_q_4_a_3_3",
    valueId: "dan tombol minta refund akan muncul secara otomatis.",
    valueEn: "and request refund button will automatically appear.",
  },
  {
    key: "faq_5_q_4_a_4",
    valueId: "Pilih alasan pembatalan dan masukkan email Anda.",
    valueEn: "Select your cancellation reason and insert your email.",
  },
  {
    key: "faq_5_q_4_a_5_1",
    valueId: "Tekan ",
    valueEn: "Click ",
  },
  {
    key: "faq_5_q_4_a_5_2",
    valueId: "Minta Refund ",
    valueEn: "Request Refund ",
  },
  {
    key: "faq_5_q_4_a_5_3",
    valueId: "dan masukkan email Anda.",
    valueEn: "and insert your email.",
  },
  {
    key: "faq_5_q_4_a_6_1",
    valueId: "Tekan ",
    valueEn: "Click ",
  },
  {
    key: "faq_5_q_4_a_6_2",
    valueId: "Kirim.",
    valueEn: "Submit.",
  },
  {
    key: "faq_5_q_4_b",
    valueId: "Melalui Aplikasi Maxi Mobile:",
    valueEn: "Via Maxi Mobile App:",
  },
  {
    key: "faq_5_q_4_b_1",
    valueId: "Masuk ke akun Anda menggunakan email dan sandi Anda.",
    valueEn: "Log In to your account using your email and password.",
  },
  {
    key: "faq_5_q_4_b_2_1",
    valueId: "Tekan menu ",
    valueEn: "Go to ",
  },
  {
    key: "faq_5_q_4_b_2_2",
    valueId: "Aktivitas.",
    valueEn: "Activity ",
  },
  {
    key: "faq_5_q_4_b_2_3",
    valueId: "",
    valueEn: "menu.",
  },
  {
    key: "faq_5_q_4_b_3_1",
    valueId:
      "Pilih tanggal konsultasi Anda di kalender, atau Anda dapat tekan ",
    valueEn: "Select your consultation date on the calendar, or you can click ",
  },
  {
    key: "faq_5_q_4_b_3_2",
    valueId: "Riwayat, ",
    valueEn: "History, ",
  },
  {
    key: "faq_5_q_4_b_3_3",
    valueId: "tekan filter ",
    valueEn: "click ",
  },
  {
    key: "faq_5_q_4_b_3_4",
    valueId: "Semua Jenis, ",
    valueEn: "All Types ",
  },
  {
    key: "faq_5_q_4_b_3_5",
    valueId: "dan tekan ",
    valueEn: "filter and click ",
  },
  {
    key: "faq_5_q_4_b_3_6",
    valueId: "Konsultasi ",
    valueEn: "Consultation ",
  },
  {
    key: "faq_5_q_4_b_3_7",
    valueId: "untuk menampilkan sesi konsultasi Anda.",
    valueEn: "to show your consultation sessions.",
  },
  {
    key: "faq_5_q_4_b_4_1",
    valueId: "Klik sesi Anda. Pada halaman Sesi Terdahulu, klik ",
    valueEn: "Click your session. On Past Session page, click ",
  },
  {
    key: "faq_5_q_4_b_4_2",
    valueId: "tanda jempol ke bawah ",
    valueEn: "thumb down ",
  },
  {
    key: "faq_5_q_4_b_4_3",
    valueId: "dan tombol minta refund akan muncul secara otomatis.",
    valueEn: "and request refund button will automatically appear.",
  },
  {
    key: "faq_5_q_4_b_5_1",
    valueId: "Tekan ",
    valueEn: "Click ",
  },
  {
    key: "faq_5_q_4_b_5_2",
    valueId: "Minta Refund ",
    valueEn: "Request Refund ",
  },
  {
    key: "faq_5_q_4_b_5_3",
    valueId: "dan masukkan email Anda.",
    valueEn: "and insert your email.",
  },
  {
    key: "faq_5_q_4_b_6_1",
    valueId: "Tekan ",
    valueEn: "Click ",
  },
  {
    key: "faq_5_q_4_b_6_2",
    valueId: "Kirim.",
    valueEn: "Submit.",
  },
  {
    key: "faq_5_q_4_i",
    valueId:
      "Customer service kami akan menghubungi Anda mengena refund melalui alamat email yang Anda berikan.",
    valueEn:
      "Our customer service will reach out to you about your refund via the email address you provided.",
  },
  {
    key: "content_testimony_1_position",
    valueId: "26 Tahun, Laki laki, Professional",
    valueEn: "26 Years Old, Male, Professional",
  },
  {
    key: "content_testimony_1_comment",
    valueId:
      "Bagi kamu yang membutuhkan konseling untuk masalah kehidupan, hubungan, dan pekerjaan... jangan menyerah! Konselor Maxi sangat membantu!",
    valueEn:
      "For those of you who needs counseling for life, relationship and work issues... don’t give up! Maxi counselors are very helpful!",
  },
  {
    key: "content_testimony_2_position",
    valueId: "30 Tahun, Perempuan, HR Lead",
    valueEn: "30 Years Old, Female, HR Lead",
  },
  {
    key: "content_testimony_2_comment",
    valueId:
      "Maxi menyediakan solusi yang lebih baik untuk Kesejahteraan Karyawan dengan harga yang lebih kompetitif dan perlakuan yang lebih baik.",
    valueEn:
      "Maxi provides a better solution for an Employee Wellbeing with a more competitive price and better treatment.",
  },
  {
    key: "content_testimony_3_position",
    valueId: "24 Tahun, Perempuan, CEO",
    valueEn: "24 Years Old, Female, CEO",
  },
  {
    key: "content_testimony_3_comment",
    valueId:
      "Ini adalah pertama kalinya saya memiliki platform untuk menjaga kesehatan mental saya, dan saya puas dengan cara Maxi Counselor menangani sesi tersebut.",
    valueEn:
      "It is my first time of having a platform to take care of my mental health, and I’m satisfied with how Maxi Counsellor handled the session.",
  },
  {
    key: "content_testimony_4_position",
    valueId: "20 Tahun, Perempuan, Murid",
    valueEn: "20 Years Old, Female, Student",
  },
  {
    key: "content_testimony_4_comment",
    valueId:
      "Maxi membantu saya mendapatkan kepercayaan diri untuk mengungkapkan apa yang sebenarnya saya rasakan, tidak hanya dengan mengikuti sesi konseling mereka, tetapi fitur-fiturnya juga membuat kita merasa aman.",
    valueEn:
      "Maxi helps me to gain my confidence to speak about what I truly feel, not only by experiencing their counselling session, but the features also makes us feel secure.",
  },
];
