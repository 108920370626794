import { onChat } from "Helper";
import { Link } from "react-router-dom";
import { ButtonJoinSpecialist } from "./Button";
import utils from "Utils";

export function Footer() {
  return (
    <footer>
      <div className="flex max-w-7xl flex-col max-h-full mt-16 mb-36 justify-between mx-auto px-8 md:flex-row md:max-h-24">
        <div className="mb-6 sm:mb-0 sm:ml-0 text-left max-w-sm">
          <Link className="flex" to="/">
            <img className="w-12" src="/logo192.png" alt="" />
            <div className="font-semibold text-xl mt-3 ml-2">Maxi</div>
          </Link>
          <div className="mt-4">
            <div>PT Maxi Pilihan Indonesia</div>
            <div>
              Bellezza BSA 1st Floor Unit 106, Jl. Letjen Soepeno, Kebayoran
              Lama Jakarta Selatan 12210
            </div>
          </div>
          <div className="mt-4">Copyright &copy; 2023 Maxi</div>
        </div>
        <div className="flex shrink-0">
          <div className="mt-14 md:mt-0 mr-16">
            <div className="font-medium mt-3 mb-4">
              {utils.getLangByKey("footer_solutions_title")}
            </div>
            <ul className="text-left hover:text-blue-500 mb-3">
              <li>
                <Link to="/booking" className="hover:underline">
                  {utils.getLangByKey("footer_solutions_menu_1")}
                </Link>
              </li>
            </ul>
            <ul className="text-left hover:text-blue-500">
              <li>
                <Link to="/team" className="hover:underline">
                  {utils.getLangByKey("footer_solutions_menu_2")}
                </Link>
              </li>
            </ul>
          </div>
          <div className="mt-14 md:mt-0 md:mr-12">
            <div className="font-medium mt-3 mb-4">
              {utils.getLangByKey("footer_link_title")}
            </div>
            {/* <ul className='text-left hover:text-blue-500 mb-3'>
                            <li><Link to="/about" className='hover:underline'>About Us</Link></li>
                        </ul> */}
            <ul className="text-left hover:text-blue-500 mb-3">
              <li>
                <Link to="/privacy" className="hover:underline">
                  {utils.getLangByKey("footer_link_menu_1")}
                </Link>
              </li>
            </ul>
            <ul className="text-left hover:text-blue-500">
              <li>
                <Link to="/term" className="hover:underline">
                  {utils.getLangByKey("footer_link_menu_2")}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-14 md:mt-0 shrink-0">
          <div>
            <div className="font-semibold mt-3 mb-4">
              {utils.getLangByKey("footer_socmed_title")}
            </div>
            <div className="flex flex-row">
              <a
                href="https://www.instagram.com/maxiclinic.co/"
                rel="noreferrer"
                target="__blank"
              >
                <img className="h-8" src="/images/svg/instagram.svg" alt="" />
              </a>
              <a
                href="https://www.linkedin.com/company/maxiclinic/"
                className="pl-2"
                rel="noreferrer"
                target="__blank"
              >
                <img className="h-8" src="/images/svg/linkedin.svg" alt="" />
              </a>
            </div>
          </div>
          <div>
            <div className="font-semibold mt-8 mb-4">
              {utils.getLangByKey("footer_download_title")}
            </div>
            <div className="flex flex-row">
              <a
                href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.rebelworks.maxi_app_prod"
                rel="noreferrer"
                target="__blank"
              >
                <img className="h-8" src="/images/playstore.png" alt="" />
              </a>
              <a
                href="https://apps.apple.com/id/app/maxi-think-perform-better/id1632571529?itsct=apps_box_link&itscg=30200"
                className="pl-2"
                rel="noreferrer"
                target="__blank"
              >
                <img className="h-8" src="/images/appstore.png" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export function SectionDemo(props: { className?: string }) {
  return (
    <section className={`${props.className}`}>
      <div className="bg-image-line bg-cover bg-center bg-no-repeat h-72 lg:h-64">
        <div className="max-w-6xl flex justify-between items-center mx-auto flex-col pb-24 pt-10 lg:pt-24 lg:flex-row">
          <div className="h-28 text-left leading-6 px-4 mb-4 sm:mb-6 md:mb-0">
            <h2 className="font-bold text-4xl text-left sm:text-center">
              {utils.getLangByKey("footer_chat_title")}
            </h2>
            <div className="text-neutral-secondary mt-5">
              {utils.getLangByKey("footer_chat_subtitle")}
            </div>
          </div>
          <div className="self-center justify-center w-full px-4 gap-2 mx-8 flex flex-col mt-4 sm:gap-6 sm:mx-0 sm:flex-row lg:mt-0 lg:w-auto">
            <div
              className="px-12 py-4 rounded-lg bg-green-500 cursor-pointer"
              onClick={onChat}
            >
              <p className="leading-6 font-bold text-white">
                {utils.getLangByKey("footer_chat_button")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export function SectionJoinSpecialist(props: { className?: string }) {
  return (
    <section className={`${props.className}`}>
      <div className="bg-image-line-greeb bg-cover bg-center bg-no-repeat mb-20">
        <div className="max-w-2xl mx-auto center pb-24 pt-12">
          <div className="pt-8 h-28 self-center text-2xl leading-6 px-4 mb-4 sm:mb-6 md:pt-12 md:mb-0">
            <h2 className="font-bold text-2xl text-left px-6 sm:text-center">
              Start your company’s wellbeing journey today
            </h2>
          </div>
          <div className="self-center justify-center gap-2 mx-8 flex flex-col sm:gap-6 sm:mx-0 sm:flex-row">
            <ButtonJoinSpecialist></ButtonJoinSpecialist>
            <div
              className="px-6 py-3 rounded-lg border-black-app border bg-white cursor-pointer"
              onClick={onChat}
            >
              <div className="leading-6 font-bold">Contact Us</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
