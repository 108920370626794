/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Appbar from "Component/Appbar";
import { ButtonBookDemo } from "Component/Button";
import { SectionDemo } from "Component/Footer";
import { CheckField, InputField, SelectField } from "Component/Input";
import { LoadingModal } from "Component/LoadingModal";
import config from "Config";
import { listNumEmployee } from "Pages/Book/Content";
import { bookApp } from "Service";
import { RefObject, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { Carousel } from "react-responsive-carousel";
import { Link, useNavigate } from "react-router-dom";
import { adopters, personalCares, testimony } from "./Content";

import "./Style.css";
import utils from "Utils";

export default function Company() {
  const formRef = useRef<HTMLDivElement>(null);

  return (
    <div className="text-center">
      <Helmet>
        <title>
          Maxiclinic: Mental wellbeing support for working professionals
        </title>
        <meta
          name="description"
          content="Maxiclinic can help Mental wellbeing support for working professionals with webinar, assessment, and private counseling."
        />
      </Helmet>

      <div
        style={{
          backgroundImage: `url('${config.AWS_URL_IMAGES_LANDING}/images/home/bg_home.png')`,
          backgroundSize: "cover",
          height: "640px",
          backgroundPosition: "center top",
        }}
      >
        <Appbar isReverse={true}></Appbar>
        <SectionHeader
          className="wow fadeInDown"
          formRef={formRef}
        ></SectionHeader>
      </div>

      <SectionAdopter className="wow fadeInDown mb-36"></SectionAdopter>

      <SectionDescription
        shiftImage={false}
        title={utils.getLangByKey("teams_benefit_1_title")}
        desc={utils.getLangByKey("teams_benefit_1_subtitle")}
        bgImage="/images/home/bg_webinar.png"
        className="wow fadeInDown"
      ></SectionDescription>

      <SectionDescription
        shiftImage={true}
        title={utils.getLangByKey("teams_benefit_2_title")}
        desc={utils.getLangByKey("teams_benefit_2_subtitle")}
        bgImage={`${config.AWS_URL_IMAGES_LANDING}/images/home/bg_employee.png`}
        className="wow fadeInDown"
      ></SectionDescription>

      <SectionDescription
        shiftImage={false}
        title={utils.getLangByKey("teams_benefit_3_title")}
        desc={utils.getLangByKey("teams_benefit_3_subtitle")}
        bgImage={`${config.AWS_URL_IMAGES_LANDING}/images/home/bg_specialist.png`}
        className="wow fadeInDown"
      ></SectionDescription>

      <SectionReason
        className="wow fadeInDown"
        formRef={formRef}
      ></SectionReason>

      <SectionTestimony className="wow fadeInDown"></SectionTestimony>

      <SectionFeatured className="wow fadeInDown"></SectionFeatured>

      <div ref={formRef}>
        <SectionForm></SectionForm>
      </div>

      <SectionDemo className="wow fadeInDown"></SectionDemo>
    </div>
  );
}

function SectionHeader(props: {
  className?: string;
  formRef: RefObject<HTMLDivElement>;
}) {
  const onBookDemo = () => {
    window.scrollTo({
      behavior: "smooth",
      top: props.formRef.current?.offsetTop,
    });
  };

  return (
    <section className={`${props.className}`}>
      <article>
        <div className="max-w-7xl mx-auto px-6 lg:py-28 py-8 lg:flex text-white">
          <div className="lg:w-1/2">
            <h1 className="text-4xl p-0 max-w-lg !leading-tight font-semibold text-left md:text-5xl">
              {utils.getLangByKey("teams_banner_title")}
            </h1>
            <p className="max-w-lg !my-5 pb-2 text-left opacity-80">
              {utils.getLangByKey("teams_banner_subtitle")}
            </p>
            <div className="mx-auto self-center sm:flex justify-start gap-4">
              <div
                className="mb-4 sm:mb-0 px-6 py-3 rounded-lg bg-white cursor-pointer"
                onClick={onBookDemo}
              >
                <div className="leading-6 font-bold text-black-app">
                  {utils.getLangByKey("teams_banner_book")}
                </div>
              </div>
              <Link
                className="cursor-pointer px-6 py-3 rounded-lg"
                to="/booking"
              >
                <div className="leading-6 font-medium">
                  {utils.getLangByKey("teams_banner_browse")}
                </div>
              </Link>
            </div>
          </div>
          <div className="lg:w-1/2 lg:mt-0 mt-20"></div>
        </div>
      </article>
    </section>
  );
}

function SectionAdopter(props: { className?: string }) {
  let length = adopters.length;
  let mid = Math.round(length / 2);
  let topAdopters = adopters.slice(0, mid);
  let bottomAdopters = adopters.slice(mid, length);

  return (
    <section className={`${props.className} bg-slate-100 py-4 lg:py-16 px-6`}>
      <div className="px-4 max-w-6xl mx-auto overflow-hidden">
        <h4 className="text-2xl leading-8 translate-y-3 mb-6 font-semibold">
          {utils.getLangByKey("teams_partners_title")}
        </h4>

        {/* For Small Device */}
        <div className="whitespace-nowrap animate-running-top">
          {[0, 1].map((val) => {
            return (
              <div key={`top-small-key-${val}`} className="inline-block">
                {topAdopters.map((val, i) => {
                  return (
                    <div
                      key={`top-small-inner-item-${i}`}
                      className="w-24 mx-6 inline-block"
                    >
                      <img className="object-center" src={val.image} alt="" />
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className="whitespace-nowrap animate-running-right-bottom">
          {[0, 1].map((val) => {
            return (
              <div key={`bottom-small-key-${val}`} className="inline-block">
                {bottomAdopters.map((val, i) => {
                  return (
                    <div
                      key={`bottom-small-inner-item-${i}`}
                      className="w-24 mx-6 inline-block"
                    >
                      <img className="object-center" src={val.image} alt="" />
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

function SectionDescription(props: {
  className?: string;
  bgImage: string;
  title: string;
  desc: string;
  shiftImage: boolean;
}) {
  return (
    <section className={`${props.className}`}>
      <article>
        <div className="mb-12 max-w-md px-6 mx-auto flex flex-col text-start md:max-w-6xl md:flex-row">
          <div
            className={`mb-12 md:pr-4 md:mb-0 md:w-1/2 self-center order-last mt-12 md:mt-0 ${
              props.shiftImage ? "md:order-first md:mr-9" : "md:ml-9"
            }`}
          >
            <h2 className="text-2xl font-semibold leading-5">{props.title}</h2>
            <h3 className="py-4">{props.desc}</h3>
          </div>
          <div className="md:w-1/2">
            <img src={props.bgImage} alt="" />
          </div>
        </div>
      </article>
    </section>
  );
}

function SectionReason(props: {
  className?: string;
  formRef: RefObject<HTMLDivElement>;
}) {
  const onBookDemo = () => {
    window.scrollTo({
      behavior: "smooth",
      top: props.formRef.current?.offsetTop,
    });
  };

  return (
    <section className={props.className}>
      <div className="mx-6">
        <div className="mx-auto px-4 pb-64 mb-80 md:pb-8 md:mb-0 rounded-3xl max-w-6xl mt-12 text-left relative bg-blue-background md:mt-56">
          <div className="px-8 pt-10 pb-4 md:pb-6 md:p-16 md:w-1/2">
            <div className="text-2xl font-semibold text-black-app">
              {utils.getLangByKey("teams_why_title")}
            </div>
            <div className="mt-4 opacity-60 mb-8">
              {utils.getLangByKey("teams_why_subtitle")}
            </div>
            <div className="flex">
              <div
                className="mb-4 sm:mb-0 px-6 py-3 rounded-lg leading-6 font-bold text-white bg-black-app cursor-pointer"
                onClick={onBookDemo}
              >
                {utils.getLangByKey("teams_why_button")}
              </div>
            </div>
          </div>
          <img
            className="z-10 absolute w-4/5 left-0 translate-x-1/10-8px md:left-auto md:translate-x-0 md:-top-1/4 md:right-8 md:w-80"
            src={`${config.AWS_URL_IMAGES_LANDING}/images/home/bg_reason.png`}
            alt=""
          />
        </div>
      </div>
    </section>
  );
}

function SectionTestimony(props: { className?: string }) {
  return (
    <section className={`${props.className}`}>
      <div className="px-6">
        <div className="mb-24 mt-24 mx-auto z-20 relative max-w-6xl">
          <div className="absolute bg-blue-background -z-10 w-full h-full rounded-3xl"></div>
          <h2 className="text-2xl font-bold leading-5 mb-12 pt-12">
            {utils.getLangByKey("teams_testimony_title")}
          </h2>
          <article>
            <Carousel
              className="mx-auto max-w-4xl testimony pb-24 z-20"
              autoPlay={true}
              infiniteLoop={true}
              showThumbs={false}
              statusFormatter={() => ""}
              renderIndicator={(e, isSelected, index, label) => {
                return <></>;
                // return (
                //     <div className="h-12 inline-block">
                //         <div onClick={ e } className={ `mt-28 w-3 h-3 rounded-xl inline-block
                // mx-3 cursor-pointer ease-in-out
                // transition duration-500 ${isSelected ? "bg-blue-app animate-dot" : "border border-blue-app"}` }></div>
                //     </div>
                // );
              }}
              renderArrowPrev={(e) => {
                return (
                  <div
                    className="absolute z-10 -bottom-12 left-0 right-0 mr-8 cursor-pointer"
                    onClick={e}
                  >
                    <FontAwesomeIcon className="text-xl" icon={faChevronLeft} />
                  </div>
                );
              }}
              renderArrowNext={(e) => {
                return (
                  <div
                    className="absolute z-10 -bottom-12 right-0 left-0 ml-8 cursor-pointer"
                    onClick={e}
                  >
                    <FontAwesomeIcon
                      className="text-xl"
                      icon={faChevronRight}
                    />
                  </div>
                );
              }}
              animationHandler="fade"
              transitionTime={500}
            >
              {testimony.map((val, i) => {
                return (
                  <div
                    key={`"testimony-${i}"`}
                    className="px-12 md:px-28 z-10 text-center"
                  >
                    <div className="img h-24 w-24 mx-auto mb-6 border border-black border-opacity-30 rounded-3xl">
                      <img src={val.image} alt="" />
                    </div>
                    <h2 className="subtitle sm:text-xl font-medium">
                      {val.subtitle}
                    </h2>
                    <div className="flex mt-6">
                      <div className="flex-grow text-sm sm:text-base">
                        <h2 className="py-4">
                          <span className="title font-semibold">
                            {val.name} -{" "}
                          </span>
                          <span>{val.position}</span>
                        </h2>
                        <p>
                          <span>Industry : </span>
                          <span className="font-semibold">
                            {val.companyIndustry}
                          </span>
                        </p>
                      </div>
                      {/* <div className='img h-20 w-20 mx-auto border border-black border-opacity-30 rounded-2xl hidden sm:block'>
                                                    <img src={ val.image } alt="" />
                                                </div> */}
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </article>
        </div>
      </div>
    </section>
  );
}

function SectionFeatured(props: { className?: string }) {
  return (
    <section className={`${props.className}`}>
      <div className="px-8">
        <div className="my-20 max-w-6xl mx-auto flex flex-col justify-start md:justify-between lg:flex-row">
          <h2 className="text-3xl text-left leading-5 mb-12">
            {utils.getLangByKey("teams_featured_title")}
          </h2>
          <div className="flex flex-wrap gap-8">
            <img
              className="h-8 md:w-min object-contain"
              src={`${config.AWS_URL_IMAGES_LANDING}/images/logo_techinasia.png`}
              alt=""
            />
            <img
              className="h-8"
              src={`${config.AWS_URL_IMAGES_LANDING}/images/logo_idx.png`}
              alt=""
            />
            <img
              className="h-8"
              src={`${config.AWS_URL_IMAGES_LANDING}/images/logo_dailysocial.png`}
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
}

function SectionForm() {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const [userType, setUserType] = useState("-");
  const [other, setOther] = useState("");
  // const [listIndustry, setListIndustry] = useState<any[]>([]);
  const [isSendEmail, setSendEmail] = useState(false);

  const isEnable = isSendEmail ? false : userType !== "";

  // const ItemType = (props: { title: string, desc: string, className?: string, onClick?: () => void; }) => {
  //     return (
  //         <div className={ `bg-slate-100 rounded-md text-left px-4 py-6 ${props.className}` } onClick={ props.onClick }>
  //             <div className='font-semibold text-lg mb-2'>{ props.title }</div>
  //             <div className='opacity-60'>{ props.desc }</div>
  //         </div>
  //     );
  // };

  // const onChangeUserType = (val: string) => {
  //     if (val === userType) {
  //         setUserType('');
  //     } else {
  //         setUserType(val);
  //     }
  // };

  const onSubmit = async (val: any) => {
    if (other !== "") {
      const sources = val.sources as string[];
      const length = sources.length;
      for (let i = 0; i < length; i++) {
        if (sources[i] === "Other") {
          sources[i] = other;
          break;
        }
      }
    }

    setSendEmail(true);

    await bookApp({
      ...val,
      role: userType,
      sources: (val.sources as string[]).join(", "),
    });

    setSendEmail(false);
    navigate("/booked");
  };

  // useEffect(() => {
  //     getListIndustry()
  //         .then((val) => {
  //             setListIndustry(val.data);
  //         });
  // }, []);

  return (
    <div className="max-w-7xl mx-auto mb-36">
      <LoadingModal show={isSendEmail}>Send Email...</LoadingModal>
      <div className="lg:bg-white rounded-md px-6 text-left">
        <div className="font-bold text-center text-2xl mb-12">
          {utils.getLangByKey("teams_book_title")}
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputField
            className="my-6"
            label={utils.getLangByKey("teams_book_email_title")}
            placeholder={utils.getLangByKey("teams_book_email_hint")}
            type="email"
            {...register("email", {
              required: true,
              pattern: /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/i,
            })}
          ></InputField>
          <InputField
            className="my-6"
            label={utils.getLangByKey("teams_book_phone_title")}
            placeholder={utils.getLangByKey("teams_book_phone_hint")}
            type="number"
            {...register("phone", { required: true })}
          ></InputField>
          <InputField
            className="my-6"
            label={utils.getLangByKey("teams_book_company_title")}
            placeholder={utils.getLangByKey("teams_book_company_hint")}
            {...register("company_name")}
          ></InputField>
          <SelectField
            defaultValue=""
            className="my-6"
            label={utils.getLangByKey("teams_book_employee_title")}
            {...register("num_employee")}
          >
            <option value="" disabled>
              {utils.getLangByKey("teams_book_employee_hint")}
            </option>
            {listNumEmployee.map((val, i) => {
              return (
                <option key={i} value={val}>
                  {val}
                </option>
              );
            })}
          </SelectField>
          {/* <SelectField
                        defaultValue=''
                        className='my-6'
                        label='Industry (If Applicable)'
                        { ...register("industry") }
                    >
                        <option value="" disabled>Choose Industry</option>
                        {
                            listIndustry.map((val, i) => {
                                return (
                                    <option key={ i } value={ val.set_key }>{ val.set_label }</option>
                                );
                            })
                        }
                    </SelectField> */}

          <div className="font-semibold mb-3">
            {utils.getLangByKey("teams_book_hear_title")}
          </div>
          <div className="text-sm opacity-60">
            {utils.getLangByKey("teams_book_hear_subtitle")}
          </div>
          <div className="flex flex-wrap gap-4 mt-4">
            <CheckField
              value="Intagram"
              {...register("sources", { required: true })}
              label="Instagram"
            ></CheckField>
            <CheckField
              value="Linkedln"
              {...register("sources", { required: true })}
              label="Linkedln"
            ></CheckField>
            <CheckField
              value="Google Search"
              {...register("sources", { required: true })}
              label="Google Search"
            ></CheckField>
            <CheckField
              value="Press Release"
              {...register("sources", { required: true })}
              label="Press Release"
            ></CheckField>
          </div>
          <div>
            <div className="flex mt-4 gap-4">
              <CheckField
                value="Other"
                {...register("sources", { required: true })}
                className="mt-1 shrink-0"
                label={utils.getLangByKey("global_other")}
              ></CheckField>
              <InputField
                inputclassname="py-1"
                placeholder={utils.getLangByKey("global_other")}
                onChange={(val) => setOther(val.target.value)}
              ></InputField>
            </div>
          </div>

          <div className="w-full">
            <div className="flex justify-center">
              <input
                disabled={!isEnable}
                type="submit"
                className={`mt-8 block max-w-xl w-full px-6 py-3 rounded-lg bg-black-app cursor-pointer leading-6 font-bold text-white text-center ${
                  isEnable ? "" : "bg-black-app/25 cursor-not-allowed"
                }`}
                value={utils.getLangByKey("teams_book_button")}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
