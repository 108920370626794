import Appbar from 'Component/Appbar';
import { ButtonJoinSpecialist } from 'Component/Button';
import { Divider } from 'Component/Divider';
import { SectionJoinSpecialist } from 'Component/Footer';
import { CheckField, EmailField, FileField, InputField, RadioField } from 'Component/Input';
import config from 'Config';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { reasons, topicExpertise, yearExperience } from './Content';

const acceptImage = "image/png,image/jpeg,.pdf";

// TODO: Join as specialist action
export default function SpecialistPage() {
    const location = useLocation();
    const url = new URLSearchParams(location.search);
    const joinform = url.get("joinform");
    const showForm = joinform === 'true';

    return (
        <div className="text-center">
            {
                showForm ? (
                    <>
                        <Appbar isReverse={ false }></Appbar>
                        <SectionForm></SectionForm>
                    </>
                ) : (
                    <>
                        <div className='pb-28' style={ { backgroundImage: `url('${config.AWS_URL_IMAGES_LANDING}/images/employer/bg_home.png')`, backgroundSize: 'cover', backgroundPosition: 'center top' } }>
                            <Appbar isReverse={ false }></Appbar>

                            <SectionHeader className="wow fadeInDown"></SectionHeader>

                            <SectionSelectSpecialist className="wow fadeInDown"></SectionSelectSpecialist>

                            <SectionReason className="wow fadeInDown"></SectionReason>

                            <SectionJoinSpecialist className="wow fadeInDown"></SectionJoinSpecialist>
                        </div>
                    </>
                )
            }
        </div>
    );
}

function SectionHeader(props: { className?: string; }) {
    return (
        <section className={ `${props.className}` }>
            <article>
                <div className="max-w-7xl mx-auto px-6 lg:py-28 py-8 lg:flex">
                    <div className='lg:w-1/2'>
                        <h1 className='text-4xl p-0 max-w-lg !leading-normal font-semibold text-left md:text-5xl'>
                            Extending your care to the workplace
                        </h1>
                        <p className='max-w-lg md:mt-3 !my-7 text-left opacity-80'>We welcome specialists who share our vision in putting an end to workplace suffering.</p>
                        <div className="mx-auto !mt-10 self-center sm:flex justify-start gap-4">
                            <div className='flex'>
                                <ButtonJoinSpecialist></ButtonJoinSpecialist>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-1/2 lg:mt-0 mt-20">
                        <div className='flex justify-center lg:justify-end'>
                            <div className='relative'>
                                <img className='max-h-96 object-cover' src={ `${config.AWS_URL_IMAGES_LANDING}/images/specialist/bg_home.png` } alt="" />
                                <div className='absolute left-1/2 -translate-x-1/2 -bottom-10 lg:-bottom-10 lg:-left-20 bg-white shadow-sm p-4 rounded-lg w-60'>
                                    <div className='flex justify-between'>
                                        <div className='text-left'>
                                            <div className='mb-1 font-semibold text-lg'>Putri Afiany</div>
                                            <div className='text-sm opacity-60'>Head of Wellbeing</div>
                                        </div>
                                        <div className='ml-6 self-center'>
                                            <div className='p-3 rounded-full' style={ { backgroundColor: '#292A2E' } }>
                                                <img src={ `${config.AWS_URL_IMAGES_LANDING}/images/specialist/icon_message.svg` } alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='absolute -top-9 right-0 lg:top-8 lg:-right-8'>
                                    <div className='p-4 rounded-2xl' style={ { backgroundColor: '#D2EDF9' } }>
                                        <img src={ `${config.AWS_URL_IMAGES_LANDING}/images/specialist/icon_medal.svg` } alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </section>
    );
}

function SectionSelectSpecialist(props: { className?: string; }) {
    return (
        <section className={ `${props.className}` }>
            <div className='px-8 bg-slate-100 py-20'>
                <div className='max-w-7xl mx-auto text-center'>
                    <div className='mb-5 text-3xl font-semibold'>How we select our specialists</div>
                    <div className='text-black-app/60'>Our specialists are unique individuals selected carefully based on their specific strengths in their chosen expertise. They have excellent knowledge of counseling in their area of expertise and a passion for helping others reach their potential. Our specialists undergo a thorough screening and training process at Maxi to ensure high quality and integrity.</div>
                </div>
            </div>
        </section>
    );
}


function SectionReason(props: { className?: string; }) {
    const Item = (props: { title: string, desc: string, imgUrl: string; }) => {
        return (
            <div className='rounded-3xl bg-white shadow-sm text-center w-full mb-12'>
                <img className='w-full rounded-t-3xl' src={ props.imgUrl } alt="" />
                <div className='px-5 pb-9 pt-2'>
                    <div className='mt-4 text-2xl font-semibold'>{ props.title }</div>
                    <div className='mt-2 text-slate-800'>{ props.desc }</div>
                </div>
            </div>
        );
    };

    return (
        <section className={ props.className }>
            <div className='py-16'>
                <div className='px-6 max-w-7xl mx-auto text-left'>
                    <div className='font-semibold text-3xl mb-3'>Why do specialists love Maxi</div>
                    <div className='opacity-60'>We support you so that you can support your clients</div>
                    <div className='flex flex-col md:flex-row justify-center mt-16 gap-8'>
                        {
                            reasons.map((val, i) => {
                                return (
                                    <Item
                                        key={ i }
                                        { ...val }
                                    ></Item>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

function SectionForm(props: { className?: string; }) {
    const { register, handleSubmit } = useForm();

    const onSubmit = (val: any) => {
        console.log("Value : ", val);
        if ((val.degreeCertificate as any[]).length === 0) {
            return toast.warning('input degree certificate file');
        }

        if ((val.personalIdentification as any[]).length === 0) {
            return toast.warning('input personal identification file');
        }

        // const data = {
        //     status: "test",
        //     language: "id",
        //     first_name: "Hello",
        //     last_name: "World",
        //     email: "zaen@maxiapp.co",
        //     country: "Indonesia",
        //     city: "Jakarta",
        //     phone: "07897879798",
        //     experiences: "Experiences",
        //     expertise: "Expertise",
        //     personal_identification: "",
        //     degree_certificate: ""
        // };

        console.log("Submit", val);
    };

    return (
        <div className={ props.className }>
            <div className='px-6'>
                <div className='pt-20 pb-8 px-8 rounded-md shadow-sm max-w-7xl mx-auto'>
                    <div className='max-w-4xl mx-auto mb-28'>
                        <div className='text-3xl mb-5 font-semibold'>How you can be involved</div>
                        <div className='text-lg opacity-60'>You can help by sharing tips and tricks, experiences, advice, and jumping into the comments of Maxi’s users in anonymous forums. Oh! You can also match with them and provide assistance for them, of course with great flexibility</div>
                    </div>
                    <form className='text-left' onSubmit={ handleSubmit(onSubmit) }>
                        <h2 className='text-lg font-semibold mb-6'>PERSONAL INFORMATION</h2>
                        <h3 className='font-semibold'>Status</h3>
                        <div className='text-sm opacity-60 mb-4'>Tick one or more</div>
                        <div className='flex flex-col gap-2 md:flex-row md:gap-4'>
                            <RadioField { ...register('status', { required: true }) } label='Psychologist'></RadioField>
                            <RadioField { ...register('status', { required: true }) } label='Coach'></RadioField>
                            <RadioField { ...register('status', { required: true }) } label='Psych Major'></RadioField>
                        </div>
                        <Divider className='my-8'></Divider>
                        <h3 className='font-semibold'>Language</h3>
                        <div className='text-sm opacity-60 mb-4'>Tick one or more</div>
                        <div className='flex flex-col gap-2 md:flex-row md:gap-4'>
                            <CheckField { ...register('language', { required: true }) } label='English'></CheckField>
                            <CheckField { ...register('language', { required: true }) } label='Indonesia'></CheckField>
                        </div>
                        <div className='flex gap-4 mt-2'>
                            <CheckField { ...register('language', { required: true }) } label='Other'></CheckField>
                            <InputField { ...register('otherLanguage') } className='ml-3' inputclassname='py-1'></InputField>
                        </div>
                        <Divider className='my-4 md:my-8'></Divider>
                        <div className='flex flex-col md:flex-row md:gap-8'>
                            <InputField { ...register('firstName', { required: true }) } className='w-full' label='First Name' placeholder='Input First Name'></InputField>
                            <Divider className='my-4 md:hidden'></Divider>
                            <InputField { ...register('lastName', { required: true }) } className='w-full' label='Last Name' placeholder='Input Last Name'></InputField>
                        </div>
                        <Divider className='my-4 md:my-8'></Divider>
                        <div className='flex flex-col md:flex-row md:gap-8'>
                            <EmailField { ...register('email', { required: true }) } className='w-full' label='Email Address' placeholder='Input Email Address'></EmailField>
                            <Divider className='my-4 md:hidden'></Divider>
                            <InputField { ...register('phone', { required: true, pattern: new RegExp('[0-9]{8}[0-9]+') }) } type='tel' className='w-full' label='Mobile Phone' placeholder='Input Mobile Phone'></InputField>
                        </div>
                        <Divider className='my-4 md:my-8'></Divider>
                        <div className='flex flex-col md:flex-row md:gap-8'>
                            <InputField { ...register('country', { required: true }) } className='w-full' label='Country' placeholder='Input Country'></InputField>
                            <Divider className='my-4 md:hidden'></Divider>
                            <InputField  { ...register('city', { required: true }) } className='w-full' label='City' placeholder='Input City'></InputField>
                        </div>
                        <div>
                            <Divider className='my-8'></Divider>
                            <LabelFile className='mb-3'
                                label='Personal Identification'
                                sublabel='Attach personal identification information (KTP/Passport)'
                            ></LabelFile>
                            <FileField
                                register={ register }
                                name='personalIdentification'
                                accept={ acceptImage }
                            ></FileField>
                        </div>
                        <div>
                            <Divider className='my-8'></Divider>
                            <LabelFile className='mb-3'
                                label='Degree Certificate'
                                sublabel='Attach relevant degree certificate (Ijazah/SKL/Transkip Nilai/serupa)'
                            ></LabelFile>
                            <FileField
                                register={ register }
                                name='degreeCertificate'
                                accept={ acceptImage }
                            ></FileField>
                        </div>
                        <Divider className='my-8'></Divider>
                        <h3 className='font-semibold mb-4'>Years of Work Experience</h3>
                        <div className='flex flex-col gap-2 md:flex-row md:gap-4'>
                            {
                                yearExperience.map((val, i) => {
                                    return (
                                        <RadioField { ...register('experiences', { required: true }) } key={ i } label={ val }></RadioField>
                                    );
                                })
                            }
                        </div>
                        <Divider className='my-8'></Divider>
                        <h2 className='text-lg font-semibold mb-6'>TOPICS OF EXPERTISE</h2>
                        <h3 className='font-semibold'>Topics of Expertise</h3>
                        <div className='text-sm opacity-60 mb-4'>Tick one or more</div>
                        <div className='flex flex-wrap flex-col gap-2 md:flex-row md:gap-4'>
                            {
                                topicExpertise.map((val, i) => {
                                    return (
                                        <CheckField { ...register('expertise', { required: true }) } key={ i } label={ val }></CheckField>
                                    );
                                })
                            }
                        </div>
                        <div className='flex gap-4 mt-2 md:mt-4'>
                            <CheckField label='Other'></CheckField>
                            <InputField { ...register('otherExpertise') } className='ml-3' inputclassname='py-1'></InputField>
                        </div>
                        <Divider className='my-8'></Divider>
                        <h2 className='text-lg font-semibold mb-6'>INTRODUCE YOURSELF</h2>
                        <h3 className='font-semibold'>Short Introduction Video</h3>
                        <div className='text-sm opacity-60 mt-4 mb-1'>Please upload a 1-minute introduction video of yourself by explaining about:</div>
                        <div className='text-sm opacity-60 my-1'>1. A brief about yourself</div>
                        <div className='text-sm opacity-60 my-1'>2. What does being mentally healthy mean to you?</div>
                        <div className='text-sm opacity-60 my-1'>3. How do you usually deal with your stress?</div>
                        <div className='text-sm opacity-60 my-1'>4. What are you topics of interest, and why did you choose those topics?</div>
                        <div className='text-sm opacity-60 mb-4 mt-4'>You can use any languages that you are comfortable with.</div>
                        <InputField { ...register('videoUrl', { required: true }) } className='w-full' label='Video URL' placeholder='Input Your Video URL'></InputField>

                        <div className='flex justify-end mt-8'>
                            <input type='submit' className='mb-4 sm:mb-0 px-6 py-3 rounded-lg leading-6 font-bold text-white bg-black-app cursor-pointer' value='Submit' />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

function LabelFile(props: { className?: string; label: string; sublabel: string; }) {
    return (
        <div className={ props.className }>
            <div className='font-semibold mb-1'>{ props.label }</div>
            <div className='text-sm opacity-60'>{ props.sublabel }</div>
        </div>
    );
}
