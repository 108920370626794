import Appbar from 'Component/Appbar';
import FloatingButton from 'Component/FloatingChat';
import config from 'Config';
import { calendlyUrl, onChat, onDownloadApp } from 'Helper';
import { Link } from 'react-router-dom';

export default function BookedPage() {
    return (
        <div className="text-center">
            <FloatingButton onChat={ onChat }></FloatingButton>

            <div style={ { backgroundImage: `url('${config.AWS_URL_IMAGES_LANDING}/images/employer/bg_home.png')`, backgroundSize: 'cover', backgroundPosition: 'center top' } }>
                <Appbar isReverse={ false }></Appbar>
                <SectionHeader className="wow fadeInDown"></SectionHeader>
            </div>

            <SectionDemo className="wow fadeInDown"></SectionDemo>
        </div>
    );
}

function SectionHeader(props: { className?: string; }) {
    return (
        <section className={ `${props.className}` }>
            <article>
                <div className='max-w-6xl mx-auto mb-32'>
                    <img className='px-24 my-12 mx-auto' src={ `${config.AWS_URL_IMAGES_LANDING}/images/booked/bg_home.svg` } alt="" />
                    <div className='px-6'>
                        <div className='font-semibold text-2xl'>An email has been sent to your inbox</div>
                        <div className='opacity-50 max-w-sm mx-auto mt-4'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                    </div>
                    <div className='flex justify-center mt-10'>
                        <Link to='/'>
                            <div className='px-6 py-3 rounded-lg bg-black-app cursor-pointer'>
                                <p className="leading-6 font-bold text-white">Back to home</p>
                            </div>
                        </Link>
                    </div>
                </div>
            </article>
        </section>
    );
}

function SectionDemo(props: { className?: string; }) {
    return (
        <section className={ `${props.className}` }>
            <div className="bg-image-line bg-cover bg-center bg-no-repeat mb-20">
                <div className="max-w-2xl mx-auto center pb-24 pt-12">
                    <div className="pt-8 h-28 self-center text-2xl leading-6 px-4 mb-4 sm:mb-6 md:pt-12 md:mb-0">
                        <h2 className='font-bold text-2xl text-left px-6 sm:text-center'>Start your company’s wellbeing journey today</h2>
                    </div>
                    <div className="self-center justify-center gap-2 mx-8 flex flex-col sm:gap-6 sm:mx-0 sm:flex-row">
                        <div className='px-6 py-3 rounded-lg bg-black-app cursor-pointer' onClick={ onDownloadApp }>
                            <p className="leading-6 font-bold text-white">Download App</p>
                        </div>
                        <div className='px-6 py-3 rounded-lg border-black-app border bg-white'>
                            <a className="leading-6 font-bold" href={ calendlyUrl } rel="noreferrer" target="_blank">Talk to Sales</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
