import Appbar from 'Component/Appbar';
import FloatingButton from 'Component/FloatingChat';
import { SectionDemo } from 'Component/Footer';
import config from 'Config';
import { onChat, onDownloadApp } from 'Helper';
import { descriptions, supportTypeItems, supportTypes } from './Content';

export default function EmployeePage() {
    return (
        <div className="text-center">
            <FloatingButton onChat={ onChat }></FloatingButton>

            <div className='mb-28' style={ { backgroundImage: `url('${config.AWS_URL_IMAGES_LANDING}/images/employer/bg_home.png')`, backgroundSize: 'cover', backgroundPosition: 'center top' } }>
                <Appbar isReverse={ false }></Appbar>
                <SectionHeader className="wow fadeInDown"></SectionHeader>
            </div>

            <SectionSupportSystem className='wow fadeInDown'></SectionSupportSystem>

            <SectionSupportType className='wow fadeInDown'></SectionSupportType>

            <SectionQuote className='wow fadeInDown'></SectionQuote>

            <SectionDescription
                shiftImage={ false }
                title='All-in-One Mobile App'
                subtitle='On-demand Care'
                bgImage={ `${config.AWS_URL_IMAGES_LANDING}/images/employee/bg_phone.png` }
                className="wow fadeInDown"
                desc='Anonymous workplace community where your employees connect and find solutions to their work-life stressors.'
            ></SectionDescription>

            <SectionDemo className="wow fadeInDown"></SectionDemo>
        </div>
    );
}

function SectionHeader(props: { className?: string; }) {
    return (
        <section className={ `${props.className}` }>
            <article>
                <div className="max-w-7xl mx-auto px-6 lg:py-28 py-8 lg:flex">
                    <div className='lg:w-1/2'>
                        <h1 className='text-4xl p-0 max-w-lg !leading-tight font-semibold text-left md:text-5xl'>
                            Personalised care for you
                        </h1>
                        <p className='max-w-lg pb-4 my-5 text-left opacity-80'>Your personal care assistant, whenever and wherever you need it.</p>
                        <div className="mx-auto self-center sm:flex justify-start gap-4">
                            <div className='mb-4 sm:mb-0 px-6 py-3 rounded-lg bg-black-app cursor-pointer' onClick={ onDownloadApp }>
                                <div className="leading-6 font-bold text-white">Download App</div>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-1/2 lg:mt-0 mt-20">
                        <div className='flex gap-8 overflow-clip'>
                            <img src={ `${config.AWS_URL_IMAGES_LANDING}/images/employee/bg_home1.png` } alt="" />
                            <img src={ `${config.AWS_URL_IMAGES_LANDING}/images/employee/bg_home2.png` } alt="" />
                        </div>
                    </div>
                </div>
            </article>
        </section>
    );
}

function SectionDescription(props: { className?: string; bgImage: string; title: string; desc: string, shiftImage: boolean, subtitle: string; }) {
    const Item = (props: {
        icon: string;
        title: string;
        desc: string;
    }) => {
        return (
            <div className='flex gap-4 mt-6'>
                <div className='w-10 h-10 shrink-0'>
                    <img className='w-full h-full' src={ props.icon } alt="" />
                </div>
                <div>
                    <div className='mb-1'>{ props.title }</div>
                    <div className='text-sm opacity-60'>{ props.desc }</div>
                </div>
            </div>
        );
    };

    return (
        <section className={ `${props.className}` }>
            <article>
                <div className="mb-12 mt-20 max-w-md px-6 mx-auto flex flex-col text-start md:max-w-5xl md:flex-row">
                    <div className={ `mb-12 md:pr-4 md:mb-0 md:w-1/2 self-center order-last mt-12 md:mt-0 ${props.shiftImage ? 'md:order-first md:mr-9' : 'md:ml-9'}` }>
                        <h2 className='text-2xl font-semibold mt-2 leading-5'>Easy steps to use Maxi</h2>
                        <h3 className='py-4'>Our app gives you the best combination of care options that are aligned with your needs.</h3>
                        <div>
                            {
                                descriptions.map((val, i) => {
                                    return (
                                        <Item
                                            key={ i }
                                            icon={ val.icon }
                                            desc={ val.desc }
                                            title={ val.title }
                                        ></Item>
                                    );
                                })
                            }
                        </div>
                    </div>
                    <div className='md:w-1/2'>
                        <img style={ { maxHeight: '700px' } } src={ props.bgImage } alt="" />
                    </div>
                </div>
            </article>
        </section>
    );
}

function SectionSupportSystem(props: { className?: string; }) {
    const Divider = (props: { className?: string; }) => {
        return (
            <div className={ props.className }>
                <img className='w-full h-full' src={ `${config.AWS_URL_IMAGES_LANDING}/images/employee/divider_hor.svg` } alt="" />
            </div>
        );
    };

    return (
        <section className={ props.className }>
            <article>
                <div className='max-w-7xl mx-auto flex flex-col md:flex-row mb-24 p-6'>
                    <div className='md:basis-2/5 max-w-sm text-left mb-16 md:mb-0'>
                        <div className='text-4xl mb-3'>Your all-in-one wellbeing support system</div>
                        <div className='text-neutral-700'>Whether you are seeking to practice self-care or consult expert care, we have the right resources for you.</div>
                    </div>
                    <div className='basis-3/5 relative lg:pl-24'>
                        <div className='flex flex-col md:flex-row gap-8'>
                            {
                                supportTypes.slice(0, 2).map((val, i) => {
                                    return (
                                        <div key={ i }>
                                            <div className='basis-1/2 text-left relative py-4 px-8'>
                                                <div className='absolute left-0 top-0 font-bold text-6xl text-blue-app/20'>{ val.index }</div>
                                                <h3 className='font-semibold text-xl mb-2'>{ val.title }</h3>
                                                <div className='opacity-60'>{ val.description }</div>
                                            </div>
                                            <Divider className='h-2 my-8 md:hidden'></Divider>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <div className='flex flex-col md:flex-row gap-8'>
                            {
                                supportTypes.slice(2, 4).map((val, i) => {
                                    return (
                                        <div key={ i }>
                                            <div className='basis-1/2 text-left relative py-4 px-8'>
                                                <div className='absolute left-0 top-0 font-bold text-6xl text-blue-app/20'>{ val.index }</div>
                                                <h3 className='font-semibold text-xl mb-2'>{ val.title }</h3>
                                                <div className='opacity-60'>{ val.description }</div>
                                            </div>
                                            {
                                                i === 0 ? (
                                                    <Divider className='h-2 my-8 md:hidden'></Divider>
                                                ) : null
                                            }
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            </article>
        </section>
    );
}

function SectionSupportType(props: { className?: string; }) {
    const Item = (props: { title: string, desc: string; }) => {
        return (
            <div className='flex gap-5 mb-8'>
                <div className='h-5 w-5 shrink-0'>
                    <img className='w-full h-full' style={ { marginTop: '2px' } } src={ `${config.AWS_URL_IMAGES_LANDING}/images/employee/icon_check.svg` } alt="" />
                </div>
                <div>
                    <div>{ props.title }</div>
                    <div className='opacity-60 mt-2'>{ props.desc }</div>
                </div>
            </div>
        );
    };

    return (
        <section className={ props.className }>
            <article>
                <div className='bg-slate-100 py-20'>
                    <div className='max-w-7xl mx-auto p-6'>
                        <div className='flex flex-col md:flex-row gap-16'>
                            <div className='basis-1/2'>
                                <img className='ml-auto' src={ `${config.AWS_URL_IMAGES_LANDING}/images/employee/bg_supporttype.png` } alt="" />
                            </div>
                            <div className='text-left basis-1/2'>
                                <div className='text-2xl font-semibold mb-4'>
                                    Counselors, Psychologists, and Coaches
                                </div>
                                <div className='opacity-75 mb-10'>Match with our specialists and access on-demand support through voice call or video call.</div>
                                <div>
                                    {
                                        supportTypeItems.map((val, i) => {
                                            return (
                                                <Item
                                                    key={ i }
                                                    title={ val.title }
                                                    desc={ val.desc }
                                                ></Item>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </section>
    );
}

function SectionQuote(props: { className?: string; }) {
    return (
        <section className={ props.className }>
            <article>
                <div className='bg-blue-background py-20'>
                    <div className='max-w-6xl mx-auto text-center px-6'>
                        <div className='text-2xl font-semibold relative'>
                            <img className='h-8 w-8 absolute left-0 -top-2' src={ `${config.AWS_URL_IMAGES_LANDING}/images/employee/quote-up.svg` } alt="" />
                            <img className='h-8 w-8 absolute right-0 bottom-0' src={ `${config.AWS_URL_IMAGES_LANDING}/images/employee/quote-down.svg` } alt="" />
                            <div className='px-12 text-left md:text-center'>For those of you who needs counseling for life, relationship and work issues... don’t give up! Maxi counselors are very helpful</div>
                        </div>
                        <div className='flex justify-center mt-10 gap-6'>
                            <div>
                                <img className='w-14 h-14' src={ `${config.AWS_URL_IMAGES_LANDING}/images/employee/user.png` } alt="" />
                            </div>
                            <div className='text-left'>
                                <div className='font-semibold text-lg'>Maxi User</div>
                                <div className='text-sm opacity-50 mt-1'>Jakarta, Indonesia</div>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </section>
    );
}
