import { faBars, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import config from "Config";
import { onDownloadApp } from "Helper";
import { userDataAtom } from "State";
import { useAtom } from "jotai";
import { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../Component/Logo";
import { Dropdown } from "flowbite-react";
import { ArrowDown, ArrowDown2 } from "iconsax-react";
import utils from "Utils";
import { setLangCookies } from "Helper/localStorage";

export default function Appbar(props: { isReverse: boolean }) {
  const userAgent =
    navigator.userAgent || navigator.vendor || (window as any).opera;
  const mobileApp =
    /android/i.test(userAgent) ||
    (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream);

  const menus = [
    {
      label: utils.getLangByKey("navbar_get_matched"),
      link: "/get_matched",
    },
    {
      label: utils.getLangByKey("navbar_find_specialist"),
      link: "/booking",
    },
    {
      label: utils.getLangByKey("navbar_for_teams"),
      link: "/team",
    },
    {
      label: utils.getLangByKey("navbar_faq"),
      link: "/faq",
    },

    // {
    //     label: "About Us",
    //     link: "/about"
    // },
  ];

  const [profile, _] = useAtom(userDataAtom);
  const [isHide, setHide] = useState(true);

  const onMenu = () => {
    setHide(!isHide);
  };

  const pathUrl = window.location.pathname;

  return (
    <>
      <div
        className={`w-screen h-screen fixed top-0 bg-white z-20`}
        hidden={isHide}
      >
        <header className="wow fadeIn px-2">
          <div className="flex justify-between">
            <Logo></Logo>
            <FontAwesomeIcon
              className="cursor-pointer p-4 mt-3"
              onClick={onMenu}
              color="black"
              icon={faX}
              size="lg"
            ></FontAwesomeIcon>
          </div>
          <div className="text-center">
            {menus.map((val, i) => {
              return (
                <div key={i}>
                  <Link to={val.link as string}>
                    <div
                      className={`py-4 px-4 text-xl cursor-pointer ${
                        val.link === pathUrl ? "font-semibold" : ""
                      }`}
                    >
                      {val.label}
                    </div>
                  </Link>
                </div>
              );
            })}
            <a href={config.WEB_APP_URL}>
              <div className={`py-4 px-4 text-xl cursor-pointer`}>
                {profile ? profile.username : "Log In"}
              </div>
            </a>
            <div className="mx-6 absolute left-0 right-0 bottom-8">
              <div
                className="mb-4 md:mb-0 px-6 py-3 rounded-lg bg-black-app cursor-pointer"
                onClick={onDownloadApp}
              >
                <div className="leading-6 font-bold text-white">
                  {utils.getLangByKey("footer_download_title")}
                </div>
              </div>
              <div className="px-6 py-3 rounded-lg border-black-app border cursor-pointer">
                <Link to="/booking">
                  <div className="leading-6 font-bold">
                    {utils.getLangByKey("home_header_browse_specialist")}
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </header>
      </div>

      <header
        className={`wow fadeInDown max-w-7xl px-2 lg:px-1 mx-auto flex ${
          props.isReverse ? "text-white" : ""
        }`}
      >
        <Logo className="md:mx-0"></Logo>
        <div className="flex-grow"></div>
        <div className="block mt-1 lg:hidden">
          <FontAwesomeIcon
            className="cursor-pointer p-4 opacity-70"
            onClick={onMenu}
            color={`${props.isReverse ? "white" : "black"}`}
            icon={faBars}
            size="2x"
          ></FontAwesomeIcon>
        </div>
        <div className="hidden mr-6 lg:block">
          <div className="flex gap-8 mt-4">
            {menus.map((val, i) => {
              if (!val.link) {
                return null;
              }

              return (
                <div key={i} className="cursor-pointer">
                  <Link to={val.link}>
                    <div
                      className={`py-2 px-4 cursor-pointer ${
                        val.link === pathUrl ? "font-semibold" : ""
                      }`}
                    >
                      {val.label}
                    </div>
                  </Link>
                </div>
              );
            })}

            <Dropdown
              inline
              arrowIcon={false}
              label={
                <div className="flex gap-2 py-2 px-4 cursor-pointer items-center">
                  {utils.getLangActive() === "EN" ? (
                    <>
                      EN{" "}
                      <ArrowDown2
                        size={16}
                        color={props.isReverse ? `#ffffff` : `#121212`}
                      />
                    </>
                  ) : (
                    <>
                      ID{" "}
                      <ArrowDown2
                        size={16}
                        color={props.isReverse ? `#ffffff` : `#121212`}
                      />
                    </>
                  )}
                </div>
              }
              size="md"
            >
              <Dropdown.Item
                className="text-end items-start justify-start"
                onClick={() => {
                  setLangCookies("ID");
                  window.location.reload();
                }}
              >
                {utils.getLangByKey("global_lang_id")}
              </Dropdown.Item>
              <Dropdown.Item
                className="text-end items-start justify-start"
                onClick={() => {
                  setLangCookies("EN");
                  window.location.reload();
                }}
              >
                {utils.getLangByKey("global_lang_en")}
              </Dropdown.Item>
            </Dropdown>

            <a href={config.WEB_APP_URL}>
              <div
                className={`border-2 rounded-lg py-2 px-4 cursor-pointer ${
                  props.isReverse ? "border-white" : "border-black/70"
                }`}
              >
                {profile
                  ? profile.username
                  : utils.getLangByKey("global_login")}
              </div>
            </a>
          </div>
        </div>
      </header>
    </>
  );
}
