import config from "Config";

export const testimony = [
	{
		image: `${config.AWS_URL_IMAGES_LANDING}/images/customer-logo/Brandt International.png`,
		name: "Andrew Chong",
		position: "Chief Operating Officer, Brandt International",
		subtitle: `"Maxi's focus is in a critical area that is key to a successful, healthy and productive working environment"`,
		companyIndustry: "Information Technology And Services",
	},
	{
		image: `${config.AWS_URL_IMAGES_LANDING}/images/customer-logo/DJI.png`,
		name: "Harry Fang",
		position: "Chief Executive Officer, Alfatech (DJI)",
		subtitle: `"I personally think Maxi is a great idea, can't wait for it to roll out and hope it will be the next life channger for Indonesians."`,
		companyIndustry: "Construction",
	},
	{
		image: `${config.AWS_URL_IMAGES_LANDING}/images/customer-logo/Sekolah Lentera Kasih.png`,
		name: "Jeremy Widjaja",
		position: "Director, Sekolah Lentera Kasih",
		subtitle: `"Maxi is a refreshing solution for productivity of our staffs. I also believe that it can be beneficial for students"`,
		companyIndustry: "Education Management",
	},
];

export const supportTypes = [
	{
		index: "01",
		title: "1 : 1 Care",
		description:
			"Counseling can help get us unstuck from our negative thoughts and develop a more positive outlook on life.",
	},
	{
		index: "02",
		title: "Crisis Support",
		description:
			"Get timely care when you are going through emotional distress such as stress, anxiety and burnout.",
	},
	{
		index: "03",
		title: "Community",
		description:
			"Find fresh perspectives from our community in a judgment-free space, and add your own.",
	},
	{
		index: "04",
		title: "Self-Guided",
		description:
			"Our self-guided content library gives you the freedom to grow at your own pace.",
	},
];

export const descriptions = [
	{
		icon: `${config.AWS_URL_IMAGES_LANDING}/images/employee/icon_desc1.svg`,
		title: "Download and Register",
		desc: "Easily find Maxi app in Google Playstore and Apple Appstore by searching “Maxi App”.",
	},
	{
		icon: `${config.AWS_URL_IMAGES_LANDING}/images/employee/icon_desc2.svg`,
		title: "Personalise your Content",
		desc: "We guide you through a series of steps to understand your preferences",
	},
	{
		icon: `${config.AWS_URL_IMAGES_LANDING}/images/employee/icon_desc3.svg`,
		title: "Engage the Community",
		desc: "Foster connection and learn with the community through topics led by specialists.",
	},
	{
		icon: `${config.AWS_URL_IMAGES_LANDING}/images/employee/icon_desc4.svg`,
		title: "Book 1 : 1 Counseling",
		desc: "Match with a specialist who can help you with your personal issues in a private voice call or video call session.",
	},
];

export const supportTypeItems = [
	{
		title: "Counselors",
		desc: "Counselors help you manage personal issues such as anxiety and stress, and educate you on strategies that can help you overcome your problems.",
	},
	{
		title: "Psychologists",
		desc: "Psychologists help assess and diagnose your mental, physical or behavioral health issues and develop treatment plans for you.",
	},
	{
		title: "Coaches",
		desc: "Coaches help clarify your goals, identify obstacles and create actionable plans to help you reach your goals.",
	},
];
