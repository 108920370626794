export const listNumEmployee = [
	"2 - 10 employees",
	"11 - 50 employees",
	"51 - 200 employees",
	"201 - 500 employees",
	"501+ employees",
];

export const employeeRoles = [
	{
		title: "Employer",
		desc: "I am looking to support my team",
		id: "Employer",
	},
	{
		title: "Employee",
		desc: "I am looking for personal support",
		id: "Employee",
	},
	{
		title: "Specialist",
		desc: "I am interested in delivering care",
		id: "Specialist",
	},
];
