export const personalCares = [
	{
		title: "Personalised Care",
		subtitle: "Maxi is a wellbeing platform that helps you live your best life",
		imageUrl: "",
	},
	{
		title: "Self Screening",
		subtitle:
			"Online screening is one of the quickest and easiest ways to test if you need to seek further help.",
		imageUrl: "/images/home/bg_personalise1.png",
	},
	{
		title: "Private Counseling",
		subtitle:
			"Access our list of trained counselors, licensed psychologists and certified coaches.",
		imageUrl: "/images/home/bg_personalise2.png",
	},
	{
		title: "Guided Exercises",
		subtitle:
			"Fostering connection and support through guided exercise on your wellbeing challenges.",
		imageUrl: "/images/home/bg_personalise3.png",
	},
];

export const supportTypeItems = [
	{
		title: "Counselors",
		desc: "Counselors help you manage personal issues such as anxiety and stress, and educate you on strategies that can help you overcome your problems.",
	},
	{
		title: "Psychologists",
		desc: "Psychologists help assess and diagnose your mental, physical or behavioral health issues and develop treatment plans for you.",
	},
	{
		title: "Coaches",
		desc: "Coaches help clarify your goals, identify obstacles and create actionable plans to help you reach your goals.",
	},
];

export const testimony = [
	{
		name: "BP",
		position: "26 Years Old, Male, Professional",
		subtitle:
			"For those of you who needs counseling for life, relationship and work issues... don’t give up! Maxi counselors are very helpful!",
	},
	{
		name: "NR",
		position: "30 Years Old, Female, HR Lead",
		subtitle:
			"Maxi provides a better solution for an Employee Wellbeing with a more competitive price and better treatment.",
	},
	{
		name: "SA",
		position: "24 Years Old, Female, CEO",
		subtitle:
			"It is my first time of having a platform to take care of my mental health, and I’m satisfied with how Maxi Counsellor handled the session.",
	},
	{
		name: "DR",
		position: "20 Years Old, Female, Student",
		subtitle:
			"Maxi helps me to gain my confidence to speak about what I truly feel, not only by experiencing their counselling session, but the features also makes us feel secure.",
	},
];
