import Appbar from 'Component/Appbar';
import { ButtonBookDemo } from 'Component/Button';
import FloatingButton from 'Component/FloatingChat';
import { SectionDemo } from 'Component/Footer';
import config from 'Config';
import { onChat } from 'Helper';
import { ArrowCircleLeft, ArrowCircleRight } from 'iconsax-react';
import { useRef, useState } from 'react';
import { testimony } from './Content';

export default function EmployerPage() {
    return (
        <div className="text-center">
            <FloatingButton onChat={ onChat }></FloatingButton>

            <div className='mb-28' style={ { backgroundImage: `url('${config.AWS_URL_IMAGES_LANDING}/images/employer/bg_home.png')`, backgroundSize: 'cover', backgroundPosition: 'center top' } }>
                <Appbar isReverse={ false }></Appbar>
                <SectionHeader className="wow fadeInDown"></SectionHeader>
            </div>

            <SectionDescription
                shiftImage={ false }
                title='All-in-One App'
                subtitle='On-demand Care'
                bgImage={ `${config.AWS_URL_IMAGES_LANDING}/images/employer/bg_show1.png` }
                className="wow fadeInDown"
                desc='We offer a centralised mental wellbeing solution within a single mobile app so your employees feel supported wherever they are.'
            ></SectionDescription>

            <SectionDescription
                shiftImage={ true }
                title='One-Day Set Up'
                subtitle='Plug and Play Solution'
                bgImage={ `${config.AWS_URL_IMAGES_LANDING}/images/employer/bg_show2.png` }
                className="wow fadeInDown"
                desc='Have your entire workforce covered within less than one day. Our mobile-based wellbeing suite means that your employees can get instant care with a quick download.'
            ></SectionDescription>

            <SectionDescription
                shiftImage={ false }
                title='Centralised Insight'
                subtitle='Anonymised Report'
                bgImage={ `${config.AWS_URL_IMAGES_LANDING}/images/employer/bg_show3.png` }
                className="wow fadeInDown"
                desc='Managing your workforce’s wellbeing can often feel overwhelming. Our wellbeing insight provides you with a deeper understanding of your workforce’s wellbeing.'
            ></SectionDescription>

            <SectionTestimony className="wow fadeInDown"></SectionTestimony>

            <SectionDemo className="wow fadeInDown"></SectionDemo>
        </div>
    );
}

function SectionHeader(props: { className?: string; }) {
    return (
        <section className={ `${props.className}` }>
            <article className='relative'>
                <div className="max-w-screen-2xl mx-auto xl:px-0 lg:py-28 py-8 text-black">
                    <div className='text-left md:text-center px-6'>
                        <h1 className='text-4xl p-0 leading-tight font-semibold md:text-5xl'>
                            Empowering employers for change
                        </h1>
                        <p className='md:mt-3 my-8 opacity-80'>Transform the way you build an engaged and productive workplace</p>
                        <div className="flex justify-center">
                            <ButtonBookDemo></ButtonBookDemo>
                        </div>
                    </div>
                    <div className='flex gap-9 justify-center overflow-clip mt-20'>
                        <img className='object-cover shrink h-44 sm:h-72 md:h-96 lg:h-auto' src={ `${config.AWS_URL_IMAGES_LANDING}/images/employer/bg_home_1.png` } alt="" />
                        <img className='object-cover shrink h-44 sm:h-72 md:h-96 lg:h-auto' src={ `${config.AWS_URL_IMAGES_LANDING}/images/employer/bg_home_2.png` } alt="" />
                        <img className='object-cover shrink h-44 sm:h-72 md:h-96 lg:h-auto' src={ `${config.AWS_URL_IMAGES_LANDING}/images/employer/bg_home_3.png` } alt="" />
                    </div>
                </div>
            </article>
        </section>
    );
}

function SectionDescription(props: { className?: string; bgImage: string; title: string; desc: string, shiftImage: boolean, subtitle: string; }) {
    return (
        <section className={ `${props.className}` }>
            <article>
                <div className="mb-12 max-w-md px-6 mx-auto flex flex-col text-start md:max-w-6xl md:flex-row">
                    <div className={ `mb-12 md:pr-4 md:mb-0 md:w-1/2 self-center order-last mt-12 md:mt-0 ${props.shiftImage ? 'md:order-first md:mr-9' : 'md:ml-9'}` }>
                        <span className='text-slate-600'>{ props.subtitle }</span>
                        <h2 className='text-2xl font-semibold mt-2 leading-5'>{ props.title }</h2>
                        <h3 className='py-4'>{ props.desc }</h3>
                    </div>
                    <div className='md:w-1/2'>
                        <img src={ props.bgImage } alt="" />
                    </div>
                </div>
            </article>
        </section>
    );
}

function SectionTestimony(props: { className?: string; }) {
    const [index, setIndex] = useState(0);
    const ref = useRef<HTMLDivElement>(null);

    const Item = (props: { testimony: string, name: string, position: string; }) => {
        return (
            <div className='w-80 md:w-96 shrink-0 bg-white px-8 py-10 mr-8 rounded-lg'>
                <div className='pb-12 border-b opacity-60'>{ props.testimony }</div>
                <div className='font-semibold text-lg mb-2 mt-4'>{ props.name }</div>
                <div className='opacity-50'>{ props.position }</div>
            </div>
        );
    };

    return (
        <section className={ props.className }>
            <article className='bg-slate-100'>
                <div className='max-w-6xl mx-auto py-32 relative'>
                    <div className='absolute left-8 top-32 text-left w-80'>
                        <div className='text-xl font-semibold mb-6'>Employer testimonial</div>
                        <div className='opacity-60'>See why employers choose Maxi as their wellbeing solution for their workforce.</div>
                    </div>
                    <div className='absolute bottom-16 left-8 md:bottom-32'>
                        <div className='flex gap-4'>
                            <ArrowCircleLeft
                                className={ `${index === 0 ? 'text-neutral-300' : 'cursor-pointer'}` }
                                variant='Bold' size={ 42 }
                                onClick={ () => {
                                    if (index === 0) return;

                                    const i = index - 1;
                                    ref.current?.scrollTo({
                                        left: i * 384,
                                        behavior: 'smooth'
                                    });

                                    setIndex(i);
                                } }
                            ></ArrowCircleLeft>
                            <ArrowCircleRight
                                className={ `${index === 2 ? 'text-neutral-300' : 'cursor-pointer'}` }
                                variant='Bold' size={ 42 }
                                onClick={ () => {
                                    if (index === 2) return;

                                    const i = index + 1;
                                    ref.current?.scrollTo({
                                        left: i * 384,
                                        behavior: 'smooth'
                                    });

                                    setIndex(i);
                                } }
                            ></ArrowCircleRight>
                        </div>
                    </div>
                    <div className='flex shrink-0 overflow-x-hidden px-8 text-left ml-0 mt-40 md:ml-96 md:mt-0 md:px-0' ref={ ref }>
                        {
                            testimony.map((val, i) => {
                                return (
                                    <Item key={ i }
                                        testimony={ val.subtitle }
                                        name={ val.name }
                                        position={ val.position }
                                    ></Item>
                                );
                            })
                        }
                    </div>
                </div>
            </article>
        </section>
    );
}
