import Appbar from 'Component/Appbar';
import { ButtonContactUs } from 'Component/Button';
import { SectionDemo } from 'Component/Footer';
import config from 'Config';
import { onChat, onDownloadApp } from 'Helper';
import { ArrowCircleLeft, ArrowCircleRight, Magicpen, User, Verify } from "iconsax-react";
import { useRef, useState } from 'react';
import { Helmet } from "react-helmet";
import { personalCares, supportTypeItems, testimony } from './Content';

export default function _Home() {
    return (
        <div>
            <Helmet>
                <title>MAXI: Think & Perform Better</title>
                <meta
                    name="description"
                    content="Maxiapp or mental health app is An anonymous work-life community platform that improves employee mental wellness and productivity. Maxiapp is your supportive partner that can help you build a happier workplace and trust with your employees."
                />
            </Helmet>

            <div className="text-center">
                <div style={ { backgroundImage: `url('${config.AWS_URL_IMAGES_LANDING}/images/employer/bg_home.png')`, backgroundSize: 'cover', backgroundPosition: 'center top' } }>
                    <Appbar isReverse={ false }></Appbar>
                    <SectionHeader className="wow fadeInDown"></SectionHeader>
                    <SectionPersonalCare className="wow fadeInDown"></SectionPersonalCare>
                </div>

                <SectionSupportType className='wow fadeInDown'></SectionSupportType>

                <SectionTestimony className="wow fadeInDown"></SectionTestimony>

                <SectionDownload className="wow fadeInDown"></SectionDownload>

                <SectionDemo className="wow fadeInDown"></SectionDemo>
            </div>
        </div>
    );
}

function SectionHeader(props: { className?: string; }) {
    function IconDesc(props: {
        title: string;
        subtitle: string;
        icon: JSX.Element;
    }) {
        return (
            <div className='text-left'>
                <div className='flex mb-4'>
                    <div className='h-14 w-14 rounded-full p-4' style={ { backgroundColor: '#D2EDF9' } }>
                        { props.icon }
                    </div>
                    <div className='ml-4 flex flex-col justify-center'>
                        <div className='text-2xl font-medium'>{ props.title }</div>
                        <div className='text-black-app/60 mt-2 lg:hidden'>{ props.subtitle }</div>
                    </div>
                </div>
                <div className='text-black-app/60 ml-16 pl-2 hidden lg:block lg:ml-0 lg:pl-0'>
                    { props.subtitle }
                </div>
            </div>
        );
    }

    return (
        <section className={ `${props.className}` }>
            <article>
                <div className="max-w-7xl mx-auto px-6 mb-20 lg:mb-0 lg:py-28 pt-8 lg:flex lg:max-h-600 overflow-hidden">
                    <div className="lg:w-1/2 lg:order-last">
                        <div className='flex justify-center'>
                            <div className='w-40 lg:w-96'>
                                <img className='' src="/images/home/bg_phone.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='mt-6 lg:mt-8 lg:w-1/2'>
                        <div className='text-center mx-auto max-w-lg lg:mx-0 lg:text-left'>
                            <h1 className='text-4xl p-0 !leading-snug font-semibold md:text-5xl'>
                                Your wellbeing partner for life
                            </h1>
                            <p className='!my-5 pb-2 opacity-80'>Check in daily and receive recommendations for your wellbeing</p>
                        </div>
                        <div className="mx-auto self-center justify-center sm:flex lg:justify-start gap-4">
                            <div className='mb-4 sm:mb-0 px-6 py-3 rounded-lg bg-black-app cursor-pointer' onClick={ onDownloadApp }>
                                <div className="leading-6 font-bold text-white">Download App</div>
                            </div>
                            <div className='cursor-pointer px-6 py-3 rounded-lg border border-black-app/50' onClick={ onChat }>
                                <div className="leading-6 font-bold">Contact Us</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={ { backgroundColor: '#F3F5F9' } }>
                    <div className='max-w-7xl mx-auto flex flex-col px-6 py-24 gap-6 lg:flex-row lg:gap-24'>
                        <div className='basis-1/3'>
                            <IconDesc
                                title='Check-in your mood'
                                subtitle='Find the right words to describe your feelings and triggers'
                                icon={ <User variant='Bold'></User> }
                            ></IconDesc>
                        </div>
                        <div className='basis-1/3'>
                            <IconDesc
                                title='Get recommendations'
                                subtitle='Get recommended strategies to improve your mood'
                                icon={ <Magicpen variant='Bold'></Magicpen> }
                            ></IconDesc>
                        </div>
                        <div className='basis-1/3'>
                            <IconDesc
                                title='Explore resources'
                                subtitle='Access a wide selection of care resources'
                                icon={ <Verify variant='Bold'></Verify> }
                            ></IconDesc>
                        </div>
                    </div>
                </div>
            </article>
        </section>
    );
}

function SectionPersonalCare(props: { className?: string; }) {
    const Item = (props: { imageUrl: string, title: string, subtitle: string; }) => {
        return (
            <div className='rounded-3xl shadow-sm bg-white text-left mr-8 w-full mb-12 md:w-72'>
                <img className='w-full rounded-t-3xl' src={ props.imageUrl } alt="" />
                <div className='px-5 pb-9'>
                    <div className='mt-4 text-2xl font-semibold'>{ props.title }</div>
                    <div className='mt-2 text-slate-800'>{ props.subtitle }</div>
                </div>
            </div>
        );
    };

    const ItemHead = (props: { title: string, subtitle: string; }) => {
        return (
            <div className='text-left w-full mr-8 mb-12 md:w-80'>
                <div className='text-2xl font-semibold'>{ props.title }</div>
                <div className='mt-4 mb-8'>{ props.subtitle }</div>
                <div className='flex'>
                    <ButtonContactUs></ButtonContactUs>
                </div>
            </div>
        );
    };

    return (
        <section className={ props.className }>
            <div className='py-20 overflow-x-scroll'>
                <div className='px-6 mx-auto max-w-7xl'>
                    <div className='flex flex-col md:flex-row justify-center'>
                        {
                            personalCares.map((val, i) => {
                                if (i === 0) {
                                    return <ItemHead key={ i }
                                        title={ val.title }
                                        subtitle={ val.subtitle }
                                    ></ItemHead>;
                                }

                                return (
                                    <Item key={ i }
                                        imageUrl={ val.imageUrl }
                                        title={ val.title }
                                        subtitle={ val.subtitle }
                                    ></Item>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}


function SectionSupportType(props: { className?: string; }) {
    const Item = (props: { title: string, desc: string; }) => {
        return (
            <div className='flex gap-5 mb-8'>
                <div className='h-5 w-5 shrink-0'>
                    <img className='w-full h-full' style={ { marginTop: '2px' } } src={ `${config.AWS_URL_IMAGES_LANDING}/images/employee/icon_check.svg` } alt="" />
                </div>
                <div>
                    <div>{ props.title }</div>
                    <div className='opacity-60 mt-2'>{ props.desc }</div>
                </div>
            </div>
        );
    };

    return (
        <section className={ props.className }>
            <article>
                <div className='bg-slate-100 py-20'>
                    <div className='max-w-7xl mx-auto p-6'>
                        <div className='flex flex-col lg:flex-row gap-16'>
                            <div className='basis-1/2 flex gap-9'>
                                {/* <img src='/images/home/bg_supporttype2.png' alt="" /> */ }
                                <img className='object-fill' src='/images/home/bg_supporttype1.png' alt="" />
                            </div>
                            <div className='text-left basis-1/2'>
                                <div className='text-2xl font-semibold mb-4'>
                                    Counselors, Psychologists, and Coaches
                                </div>
                                <div className='opacity-75 mb-10'>Match with our specialists and access on-demand support through voice call or video call.</div>
                                <div>
                                    {
                                        supportTypeItems.map((val, i) => {
                                            return (
                                                <Item
                                                    key={ i }
                                                    title={ val.title }
                                                    desc={ val.desc }
                                                ></Item>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </section>
    );
}

function SectionTestimony(props: { className?: string; }) {
    const [index, setIndex] = useState(0);
    const ref = useRef<HTMLDivElement>(null);

    const Item = (props: { testimony: string, name: string, position: string; }) => {
        return (
            <div className='w-80 md:w-96 shrink-0 px-8 py-10 mr-8 rounded-lg' style={ { backgroundColor: '#F3F5F9' } }>
                <div className='pb-12 border-b border-black-app/40 opacity-60 h-36'>{ props.testimony }</div>
                <div className='font-semibold text-lg mb-2 mt-4'>{ props.name }</div>
                <div className='opacity-50'>{ props.position }</div>
            </div>
        );
    };

    return (
        <section className={ props.className }>
            <article className=''>
                <div className='max-w-7xl mx-auto pt-32 pb-24 relative'>
                    <div className='absolute left-8 top-32 text-left w-80'>
                        <div className='text-2xl font-semibold mb-6 mt-14'>What our users say</div>
                    </div>
                    <div className='absolute bottom-16 left-8 md:bottom-32'>
                        <div className='flex gap-4'>
                            <ArrowCircleLeft
                                className={ `${index === 0 ? 'text-neutral-300' : 'cursor-pointer'}` }
                                variant='Bold' size={ 42 }
                                onClick={ () => {
                                    if (index === 0) return;

                                    const i = index - 1;
                                    ref.current?.scrollTo({
                                        left: i * 320,
                                        behavior: 'smooth'
                                    });

                                    setIndex(i);
                                } }
                            ></ArrowCircleLeft>
                            <ArrowCircleRight
                                className={ `${index === 3 ? 'text-neutral-300' : 'cursor-pointer'}` }
                                variant='Bold' size={ 42 }
                                onClick={ () => {
                                    if (index === 3) return;

                                    const i = index + 1;
                                    ref.current?.scrollTo({
                                        left: i * 320,
                                        behavior: 'smooth'
                                    });

                                    setIndex(i);
                                } }
                            ></ArrowCircleRight>
                        </div>
                    </div>
                    <div className='flex shrink-0 overflow-x-hidden px-8 text-left ml-0 mt-40 md:ml-96 md:mt-0 md:px-0' ref={ ref }>
                        {
                            testimony.map((val, i) => {
                                return (
                                    <Item key={ i }
                                        testimony={ val.subtitle }
                                        name={ val.name }
                                        position={ val.position }
                                    ></Item>
                                );
                            })
                        }
                    </div>
                </div>
            </article>
        </section>
    );
}

function SectionDownload(props: { className?: string; }) {
    return (
        <section className={ props.className }>
            <div className='mx-12 mb-32'>
                <div className='mx-auto px-4 rounded-3xl max-w-7xl mt-12 text-left relative bg-blue-background lg:mt-20 lg:pb-8 lg:mb-0'>
                    <div className='px-8 pt-10 pb-4 mb-14 lg:mb-0 lg:pb-6 lg:p-16 lg:w-1/2'>
                        <div className='text-2xl font-medium text-black-app'>Download our app from the App Store & Google Play</div>
                        <div className='mt-4 opacity-60 mb-8'>Start your wellbeing journey today</div>
                        <div className='flex flex-col gap-4 md:flex-row md:gap-10'>
                            <a href='https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.rebelworks.maxi_app_prod'>
                                <div className='cursor-pointer'>
                                    <img src="/images/playstore.png" alt="" />
                                </div>
                            </a>
                            <a href='https://apps.apple.com/id/app/maxi-think-perform-better/id1632571529?itsct=apps_box_link&itscg=30200'>
                                <div className='cursor-pointer'>
                                    <img src="/images/appstore.png" alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='flex justify-center lg:hidden'>
                        <img src='/images/home/bg_download.png' alt="" />
                    </div>
                    <img className='z-10 absolute origin-bottom scale-125 right-24 bottom-0 h-full hidden lg:block' src='/images/home/bg_download.png' alt="" />
                </div>
            </div>
        </section>
    );
}
