import Appbar from 'Component/Appbar';
import { Button, Label, TextInput } from "flowbite-react";
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import authService from 'Service/auth';

export default function LoginPage() {
    const { register, handleSubmit } = useForm();

    const onLogin = async (val: any) => {
        await authService.login(val);

        toast.success('login successfull');

        window.location.href = '/dashboard';
    };

    return (
        <div className='h-screen' style={ {
            backgroundImage: 'url("/images/login/bg_home.svg")',
            backgroundRepeat: 'no-repeat',
            minHeight: '100vh',
            height: '100%',
        } }>
            <Appbar isReverse={ false }></Appbar>
            <div className='flex max-w-7xl px-6 mx-auto pb-12' style={ { height: 'calc(100% - 81px)' } }>
                <div className='lg:basis-1/2 mt-16 mx-auto'>
                    <img className='w-20 h-20 mb-14' src="/logo192.png" alt="" />
                    <div className='text-3xl mb-5'>Welcome to Maxi!</div>
                    <div className='text-4xl font-semibold mb-8'>Login to your account</div>
                    <div style={ { maxWidth: '450px' } }>
                        <form onSubmit={ handleSubmit(onLogin) }>
                            <div className='mb-6'>
                                <Label
                                    htmlFor="email"
                                    value='Email'
                                />
                                <TextInput id='email'
                                    { ...register("email", { required: true, pattern: /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/i }) }
                                    className='mt-2'
                                    placeholder='Your registered email or ID'
                                ></TextInput>
                            </div>
                            <div>
                                <Label
                                    htmlFor="password"
                                    value='Password'
                                />
                                <TextInput id='password'
                                    { ...register("password", { required: true }) }
                                    className='mt-2'
                                    placeholder='Password'
                                    type='password'
                                ></TextInput>
                            </div>
                            <div className='flex justify-end mt-4'>
                                <Link to='/forgot'>
                                    <div>Forgot Password?</div>
                                </Link>
                            </div>
                            <Button type='submit' color='dark' className='w-full mt-12'>Log In</Button>
                        </form>
                    </div>
                </div>
                <div className='basis-1/2 self-center hidden lg:block'>
                    <img className='w-full object-cover' src="/images/login/bg_home.png" alt="" />
                </div>
            </div>
        </div>
    );
}
