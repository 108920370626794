import { api } from "./api";

async function login(body: { email: string; password: string }) {
	const resp = await api.post("/v1/landing/login", body);

	return resp.data;
}

const authService = {
	login,
};

export default authService;
