const config = {
  API_URL: process.env.REACT_APP_API_URL,
  API_MOBILE_URL: process.env.REACT_APP_API_MOBILE_URL,
  DEEP_LINK: process.env.REACT_APP_MOBILE_DEEP_LINK,
  AWS_URL_IMAGES: `${process.env.REACT_APP_AWS_URL}`,
  AWS_URL_IMAGES_LANDING: `${process.env.REACT_APP_AWS_URL}/landing`,
  BOOKING_URL: process.env.REACT_APP_BOKING_URL,
  WEB_APP_URL: process.env.REACT_APP_WEB_APP_URL,
  COOKIE_DOMAIN: `${process.env.REACT_APP_COOKIE_DOMAIN}`,
  isProduction: process.env!.NODE_ENV === "production",
  useQuery: {
    fetchOnce: {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
    },
  },
  timezoneOffsetHour: Math.round(new Date().getTimezoneOffset() / -60),
};

export default config;
